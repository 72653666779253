import React, { useEffect, useState } from 'react'


export default function SearchBar(props) {

    const [searchTxt, setSearchTxt] = useState("")

    // useEffect(() => {
    //     console.log("ggggggggggggg", props.resetVal);
    //     if (props.resetVal) {
    //         onSearchClick("")
    //         setSearchTxt("")
    //         props.setReset(false)
    //     }
    // }, [props.resetVal])

    const onSearchClick = (val) => {
        let temp = props.searchData.filter((dt) => {
            return props.searchItems.some((item) => {
                return dt[item].toLowerCase().includes(val.toLowerCase());
            });
        })

        console.log("Search data", val);

        props.setFilteredDt(temp)
        props.setFilteredSearchData(temp);
    }


    return (
        <div className='d-flex h-45'>
            <input value={props.searchTxt} className='h-100 w-100 primary-text-box' type='text' placeholder={'Name/Reg. No./Email/Phone Num/OTP/PIN'} onChange={(e) => {
                setSearchTxt(e.target.value)
                onSearchClick(e.target.value)
                props.setSearchTxt(e.target.value)
                // props.setSearchVal(e.target.value)
            }} />
            {/* <PrimaryTextBox type='text' label='Name, Reg. No., Email, Phone Num, OTP, PIN' onChange={(e) => {
                setSearchTxt(e.target.value)
                onSearchClick(e.target.value)
            }} /> */}
            {/* <button className='ms-2 btn-latest' style={{height:"40px"}} onClick={(e) => {
                e.preventDefault();
                onSearchClick()
            }}>
                Search
            </button> */}
        </div>
    )
}
