import { useState, useEffect } from 'react';
import React from 'react';
import axios from 'axios';
import { Accordion, Modal, Tab, Table } from 'react-bootstrap';
import detailsIcon from "../../Assets/details-icon.png"
import './ReportsData.css'
import useGlobalData from '../../../Zustand/GlobalData';
import moment from 'moment'

const ReportsDataNew = (props) => {

    const { InitialUpdateAccountsData, accountId, usersData, schoolData, updateUsersData } = useGlobalData();
    const [reports, setReports] = useState([]);
    const [details, setDetails] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedDt2, setSelectedDt2] = useState(null);
    const [openAccordion, setOpenAccordion] = useState();


    useEffect(() => {
        let accountId = usersData.length > 0 ? usersData[0]['_lmAccountId'] : ''
        axios.get(`/alexa/user/report/${accountId}`)
            .then((res) => {
                console.log("res.data", res.data);
                setReports(res.data.data);
                setDetails(res.data.data);
                let reportscsvdata = []
                res.data.data.flatMap((report) => report.gradeCourses).map((dt2, index) => {
                    let tempObj = {
                        RegNumber: dt2.userDetails[0]['regNum'],
                        Name: dt2.userDetails[0]['userName'],
                        Email: dt2.userDetails[0]['userEmail'],
                        Course: dt2.course,
                        "Current Unitd" : dt2.currentUnit,
                        "Current Lesson" : dt2.currentLesson,
                        Grade: dt2.grade,
                        Role: dt2.userRole,
                        "Start Date": getDate(dt2.course_start_Date),
                        "End Date": dt2.course_end_date === null ? "-" : getDate(dt2.course_end_date),
                        "No Of Sessions": dt2.sessionDetails.length,
                        "Time Spent (HH/MM/SS)": dt2.timeSpentOnCoursehhmmss,
                        "Time spent in seconds": dt2.timeSpentOnCourse,
                        Status: dt2.status
                    }
                    reportscsvdata.push(tempObj)
                })
                props.setReports(reportscsvdata);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }, []);


    const handleModalOpen = (dt2) => {
        setSelectedDt2(dt2);
        setShowModal(true);
    };


    const handleModalClose = () => {
        setSelectedDt2(null);
        setShowModal(false);
    };


    const toggleAccordion = (index) => {
        setOpenAccordion((prevIndex) => (prevIndex === index ? null : index));
        // setOpenAccordion(index)
    };

    const getDate = (arg) => {
        const dateString = arg
        const dateObject = new Date(dateString);

        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1;
        const year = dateObject.getFullYear();

        const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
        return formattedDate;
    }

    const getTime = (arg1, arg2) => {
        const startTime = new Date(arg1);
        const endTime = new Date(arg2);

        const timeDifference = endTime - startTime;

        const hours = Math.floor(timeDifference / (1000 * 60 * 60)).toString().padStart(2, '0');
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60).toString().padStart(2, '0');
        const seconds = Math.floor((timeDifference / 1000) % 60).toString().padStart(2, '0');

        const formattedTimeDuration = `${hours}:${minutes}:${seconds}`;
        return formattedTimeDuration;
    }

    const getTotalTimeDuration = (totalMilliseconds) => {
        const hours = Math.floor(totalMilliseconds / (1000 * 60 * 60)).toString().padStart(2, '0');
        const minutes = Math.floor((totalMilliseconds / (1000 * 60)) % 60).toString().padStart(2, '0');
        const seconds = Math.floor((totalMilliseconds / 1000) % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }

    const convertToSeconds = (time) => {
        const [hours, minutes, seconds] = time.split(":").map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    };

    const addTime = (totalTimeSpentDuration, newTime) => {
        console.log("sssssssss", totalTimeSpentDuration, newTime);
        let seconds = convertToSeconds(newTime);

        let temp = parseInt(totalTimeSpentDuration) + parseInt(seconds);

        return temp;
    }

    const convertSecondsToTimeFormat = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(remainingSeconds).padStart(2, "0");

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };


    return (
        <div>
            <Table className='mt-3' bordered responsive hover style={{ backgroundColor: "white" }}>
                <thead>
                    <tr>
                        <th>RegNumber</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Course</th>
                        <th>Grade</th>
                        <th>Current Unit</th>
                        <th>Current Lesson</th>
                        <th>Role</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>No Of Sessions</th>
                        <th>Time Spent</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {reports.flatMap((report) => report.gradeCourses).map((dt2, index) => {
                        let totalTimeSpentDuration = 0;
                        let overallTimeSpent = 0;

                        return (
                            <React.Fragment key={dt2._id}>
                                <tr onClick={() => toggleAccordion(index)}>
                                    <td>{dt2.userDetails[0]['regNum']}</td>
                                    <td>{dt2.userDetails[0]['userName']}</td>
                                    <td>{dt2.userDetails[0]['userEmail']}</td>
                                    <td>{dt2.course}</td>
                                    <td>{dt2.grade}</td>
                                    <td>{dt2.currentUnit}</td>
                                    <td>{dt2.currentLesson}</td>
                                    <td>{dt2.userRole}</td>
                                    <td> {moment(dt2.course_start_Date).format('LL')} {moment(dt2.course_start_Date).format('LTS')}</td>
                                    <td>{dt2.course_end_date === null ? "-" :  `${moment(dt2.course_end_date).format('LL')} ${moment(dt2.course_end_date).format('LTS')}`}</td>
                                    <td>{dt2.sessionDetails.length}</td>
                                    <td>
                                        {dt2.timeSpentOnCoursehhmmss}
                                    </td>
                                    <td>{dt2.status}</td>
                                </tr>
                                {
                                    openAccordion === index ? (
                                        <tr>
                                            <td colSpan={11}>
                                                <div style={{ border: "1px solid lightgray", boxShadow: "0px 0px 2px whiteSmoke" }}>
                                                    <Table bordered responsive style={{ backgroundColor: "white" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Unit</th>
                                                                <th>Lesson</th>
                                                                <th>Result</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {dt2.lessonHistory.map((dt3) => (
                                                                <tr key={dt3._id}>
                                                                    <td>{dt3.unit ? dt3.unit : "-"}</td>
                                                                    <td>{dt3.lesson ? dt3.lesson : "-"}</td>
                                                                    <td>
                                                                        <Table bordered responsive style={{ backgroundColor: "white" }}>
                                                                            {
                                                                                dt3.history.length > 0 ? (
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Attempt Number</th>
                                                                                            <th>Lesson Key</th>
                                                                                            <th>Result</th>
                                                                                            <th>Time Spent</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                ) : null
                                                                            }
                                                                            <tbody>
                                                                                {
                                                                                    dt3.history.map((dt4) => (
                                                                                        <tr key={dt4.id}>
                                                                                            <td>{dt4.attempt_no}</td>
                                                                                            <td>
                                                                                                <Table bordered responsive style={{ backgroundColor: "white" }}>
                                                                                                    <thead>
                                                                                                        <tr key={dt4.id}>
                                                                                                            <th>Activities Completed</th>
                                                                                                            <th>Result</th>
                                                                                                            <th>Time Used</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {
                                                                                                            Object.keys(dt4.activityData).map((dt5) => {
                                                                                                                let totalActivityDuration = 0;

                                                                                                                return (
                                                                                                                    <React.Fragment key={dt5}>
                                                                                                                        <tr>
                                                                                                                            <td> {dt5} </td>
                                                                                                                            <td>
                                                                                                                                {dt4.activityData[dt5] !== undefined ? (
                                                                                                                                    <div>
                                                                                                                                        {
                                                                                                                                            dt4.activityData[dt5].map((dt7) => {
                                                                                                                                                const timeDifference = new Date(dt7.activity_end_time) - new Date(dt7.activity_start_time);
                                                                                                                                                totalActivityDuration += timeDifference;
                                                                                                                                                totalTimeSpentDuration = addTime(totalTimeSpentDuration, getTime(dt7.activity_start_time, dt7.activity_end_time));
                                                                                                                                                return (
                                                                                                                                                    <Table bordered responsive style={{ backgroundColor: "white" }}>
                                                                                                                                                        <thead>
                                                                                                                                                            <tr>
                                                                                                                                                                <th>Attempt Number</th>
                                                                                                                                                                <th>Start Date</th>
                                                                                                                                                                <th>End Date</th>
                                                                                                                                                                <th>Time Duration</th>
                                                                                                                                                            </tr>
                                                                                                                                                        </thead>
                                                                                                                                                        <tbody>
                                                                                                                                                            <tr>
                                                                                                                                                                <td> {dt7.attempts_no} </td>
                                                                                                                                                                <td> {getDate(dt7.activity_start_time)} </td>
                                                                                                                                                                <td> {getDate(dt7.activity_end_time)} </td>
                                                                                                                                                                <td>{dt7['durationhhmmss']}</td>
                                                                                                                                                            </tr>
                                                                                                                                                        </tbody>
                                                                                                                                                    </Table>
                                                                                                                                                );
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                ) : ""}
                                                                                                                            </td>
                                                                                                                            <td>{dt4.activityTimeSpent[`${dt5}hhmmss`]}</td>
                                                                                                                        </tr>
                                                                                                                    </React.Fragment>
                                                                                                                );
                                                                                                            })
                                                                                                        }
                                                                                                    </tbody>
                                                                                                </Table>
                                                                                            </td>
                                                                                            <td>
                                                                                                {dt3.timeSpentOnLessonhhmmss}
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))
                                                                                }
                                                                            </tbody>
                                                                        </Table>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </td>
                                        </tr>
                                    ) : null
                                }
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
        </div>

    );
};


export default ReportsDataNew;






