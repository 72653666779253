import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import editBtnImg from "../Assets/edit.svg";
import deleteBtnImg from "../Assets/delete.svg";
import axios from "axios";
import PopupAdminComponent from "./PopupAdminComponent";
import DeletePopupAdminComponent from "./DeletePopupAdminComponent";

const AdminComponent = (props) => {
    const [selectedRole, setSelectedRole] = useState("All");
    const [showModal, setShowModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [allData, setAllData] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [updatedRoles, setUpdatedRoles] = useState([])
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        axios
            .get("/admin/getAllAdminsData")
            .then((res) => {
                setAllData(res.data);
                setFilteredData(res.data)
                console.log("Getting Admins: ", res.data);
            })
            .catch((Err) => {
                console.log("Error", Err);
            });
    }, []);


    useEffect(() => {
        let temp = [];

        allData.forEach((dt) => {
            if (!temp.includes(dt.userRole[0])) {
                temp.push(dt.userRole[0]);
            }
        });

        setUpdatedRoles(temp);
    }, [allData]);

    useEffect(() => {
        if (selectedRole === 'All') {
            setFilteredData(allData);
        } else {
            const filtered = allData.filter((dt) => dt.userRole[0] === selectedRole);
            setFilteredData(filtered);
        }
    }, [selectedRole, allData]);

    const handleCloseDeletePopup = () => {
        setShowDeletePopup(false);
    };

    const handleShowDeletePopup = () => {
        setShowDeletePopup(true);
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = (props) => {
        setShowModal(false);
    };

    const handleEditUser = (user, index) => {
        setSelectedUser({ ...user, index });
        setShowModal(true);
    };

    const handleDeleteUser = (user, index) => {
        setSelectedUser({ ...user, index });
        setShowDeletePopup(true)
    };

    const handleRoleChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue === "") {
            return;
        }
        setSelectedRole(selectedValue);
    };

    return (
        <div style={{ marginTop: "50px" }}>
            <Container>
                <Row>
                    <Col>
                        <div className="d-flex align-items-center mt-4">
                            <label style={{ fontSize: "18px" }}>
                                <b>Filter</b>
                            </label>
                            <div className="ms-4" style={{ display: "flex", alignItems: "center" }}>
                                <label style={{ marginRight: "8px" }}>Roles</label>
                                <select style={{ width: '150px' }} className="ms-2 dropdown-slct-action" value={selectedRole} onChange={handleRoleChange}>
                                    <option value="All">All</option>
                                    {updatedRoles.map((role) => (
                                        <option key={role}>{role}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3" style={{ display: "flex", textAlign: "center" }}>
                    <Col md={12} style={{ textAlign: "start", margin: "0px auto", height: "115px" }}>
                        <Table responsive hover style={{ backgroundColor: "white" }}>
                            <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>Email-ID</th>
                                    <th>Role</th>
                                    <th>Phone No.</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {console.log("allData", allData)}
                                {filteredData.length > 0 ? (
                                    filteredData.map((dt, i) => (
                                        <tr key={i}>
                                            <td>{dt.userName}</td>
                                            <td>{dt.userEmail}</td>
                                            <td>{dt.userRole}</td>
                                            <td>{dt.userPhoneNumber}</td>
                                            <td>
                                                <div className="d-flex align-items-center jsutify-content-around">
                                                    <div style={{ textAlign: "right" }}>
                                                        <button className="edit-button" onClick={() => handleEditUser(dt, i)} >
                                                            <img style={{ cursor: "pointer" }} src={editBtnImg} width="20px" height="auto" alt="Edit" />
                                                        </button>
                                                    </div>

                                                    <button className="delete-button ms-2" onClick={() => handleDeleteUser(dt, i)}>
                                                        <img src={deleteBtnImg} width="20px" height="auto" style={{ cursor: "pointer" }} />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : ""}
                            </tbody>
                        </Table>
                        <PopupAdminComponent showModal={showModal} handleCloseModal={handleCloseModal} selectedUser={selectedUser} />
                        {showDeletePopup ? <DeletePopupAdminComponent selectedUser={selectedUser} handleShowDeletePopup={handleShowDeletePopup} handleCloseDeletePopup={handleCloseDeletePopup} /> : ""}
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default AdminComponent;

