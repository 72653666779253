import React, { useEffect, useState } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import "../../CSSFiles/common.css"
import axios from "axios";
import useGlobalData from "../../../Zustand/GlobalData";

const PopupModalAssgnedUsers = (props) => {
    // const [showModal, setShowModal] = useState(false);
    const { selectedUser, role } = props;
    const { usersData, updateAlreadyExistingUsersData, updateUserLicenseGrade, updateUserGrade, userLicenses } = useGlobalData();
    const [errorMssg, setErrorMssg] = useState("")
    const [slctdRole, setslctdRole] = useState("")
    // const [role, setRole] = useState("");
    const [users, setUsers] = useState([]);

    const [grade, setGrade] = useState([])
    const [removedGrade, setRemovedGrade] = useState([])
    const [gradeErrMsg, setGradeErrMsg] = useState("")

    const [changedGradesDt, setChangedGradesDT] = useState([])

    const [inputField, setInputField] = useState({
        regNo: "",
        fullName: "",
        emailId: "",
        role: [],
        grade: "",
        dob: "",
        phoneNo: "",
    });

    useEffect(() => {
        // console.log("Sssssssssssssxsaaaaaaaaaaaaaaaaaaas", props.selectedUser);
        // if (props.selectedUser) {
        setInputField(props.selectedUser);
        if (props.selectedUser.userRole === "teacher") {
            setGrade(props.selectedUser.grade.split(","));
        }
        else {
            setGrade(props.selectedUser.grade);
        }
        setslctdRole(props.selectedUser.userRole);
        setErrorMssg("")

        // }
    }, [props.selectedUser]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        let gradesDt = inputField.grade.split(",");
        let isMultipleGradesfound = false;

        if (name === "userRole") {
            setslctdRole(value);
            setInputField((prevInputField) => ({
                ...prevInputField,
                [name]: value,
            }));
            if (value === "teacher") {
                isMultipleGradesfound = false;
            } else {
                if (gradesDt.length > 1) {
                    isMultipleGradesfound = true;
                } else {
                    isMultipleGradesfound = false;
                }
            }
            console.log("=============", isMultipleGradesfound);
            if (isMultipleGradesfound) {
                setErrorMssg("Please Check Grades, Multiple grades are not allowed");
            }
            else {
                setErrorMssg("")
            }
        } else if (name === "userName") {
            if (value === "" || /^[a-zA-Z ]+$/.test(value)) {
                setInputField((prevInputField) => ({
                    ...prevInputField,
                    [name]: value,
                }));
                setErrorMssg("");
            } else {
                setErrorMssg("Only alphabets are allowed");
            }
        } else if (name === "emailId") {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                setInputField((prevInputField) => ({
                    ...prevInputField,
                    [name]: value,
                }));
                setErrorMssg("");
            } else {
                setErrorMssg("Invalid email address");
            }
        } else if (name === "grade") {
            let gradeTemp = "";
            if (slctdRole === "teacher") {
                setInputField((prevInputField) => ({
                    ...prevInputField,
                    [name]: props.selectedUser.grade + "," + value,
                }));
                setErrorMssg("");
                gradeTemp = grade;
            } else {
                if (/^[a-zA-Z0-9 ]*$/.test(value)) {
                    setInputField((prevInputField) => ({
                        ...prevInputField,
                        [name]: value,
                    }));
                    setErrorMssg("");
                } else {
                    setErrorMssg("Invalid characters entered in Grade");
                }
            }
            console.log("teacher grade", value, "slctdRole", slctdRole);
            //here we will add grade
        } else if (name === "dob") {
            setInputField((prevInputField) => ({
                ...prevInputField,
                [name]: value,
            }));
            setErrorMssg("");
        } else if (name === "phoneNo") {
            if (/^\d*$/.test(value)) {
                setInputField((prevInputField) => ({
                    ...prevInputField,
                    [name]: value,
                }));
                setErrorMssg("");
            } else {
                setErrorMssg("Only numbers are allowed.");
            }
        }


    };


    const onSave = () => {
        if (props.selectedUser.regNo === "") {
            setErrorMssg("Please enter Id");
            return false;
        }
        if (inputField.userName === "") {
            setErrorMssg("Please enter Full Name");
            return false;
        }
        if (inputField.grade === "") {
            setErrorMssg("Please enter Grade");
            return false;
        }
        if (inputField.dob === "") {
            setErrorMssg("Please select DOB");
            return false;
        }
        if (inputField.phoneNum === "") {
            setErrorMssg("Please enter Phone No.");
            return false;
        }

        updateUser()
        // Additional validation checks if needed
        const updatedUsers = [...users];
        updatedUsers[selectedUser.index] = { ...inputField };
        setUsers(updatedUsers);
    };

    const updateUser = async () => {
        console.log("inputfields", inputField);
        let isAllGood = true;


        let gradeTemp = "";

        let gradeHookDt = grade;
        // let finalGradeTemp = gradeHookDt.filter((grade) => !removedGrade.includes(grade))
        let finalGradeTemp = gradeHookDt.map((grade) => (removedGrade.includes(grade) ? "removed" : grade));
        console.log("finalGradeTemp", finalGradeTemp);

        let gradeForUserDt = gradeHookDt.filter((grade) => !removedGrade.includes(grade));
        if (props.selectedUser.userRole === "teacher") {


            gradeForUserDt.map((dt, i) => {
                if (gradeForUserDt.length === (i + 1)) {
                    gradeTemp += dt;
                }
                else {
                    gradeTemp += dt + ",";
                }
            })

            gradeForUserDt.map((dt) => {
                if (dt == "") {
                    isAllGood = false;
                }
            })
        }
        else {
            gradeTemp = inputField.grade
        }
        console.log("gradeTemp", gradeTemp)


        let payload = {
            id: props.selectedUser._id,
            userName: inputField.userName,
            userEmail: inputField.userEmail,
            userRole: inputField.userRole,
            regNum: inputField.regNum,
            // grade: inputField.grade,
            grade: gradeTemp,
            dob: inputField.dob,

        }

        console.log("Checking grade", props.selectedUser.grade.split(","), "grade---", gradeHookDt, "gradeTemp", gradeTemp);
        const selectedUserGrades = props.selectedUser.grade.split(",");
        const changedGrades = [];

        for (let i = 0; i < selectedUserGrades.length; i++) {
            if (selectedUserGrades[i] !== finalGradeTemp[i]) {
                changedGrades.push({
                    oldGrade: selectedUserGrades[i],
                    newGrade: finalGradeTemp[i]
                });
            }
        }

        console.log("Changed grades:", changedGrades);

        if (isAllGood) {
            axios.post('/lmAccountsUser/updateUser', payload)
                .then((res) => {
                    console.log("res", res)
                    if (res.data.message === "success") {
                        updateAlreadyExistingUsersData([res.data.updatedUser])
                        setErrorMssg("User Updated Successfully")

                        if (changedGrades.length > 0) {
                            console.log("Get license ", userLicenses);
                            let licensesDt = userLicenses;

                            const updatePromises = changedGrades.map((dt, i) => {
                                if (dt.newGrade !== "removed") {
                                    const matchingLicense = licensesDt.find((license) => license._userGrade === dt.oldGrade);
                                    if (matchingLicense) {
                                        const licenseId = matchingLicense._id;
                                        const newGrade = dt.newGrade;
                                        console.log("License ID:", licenseId, "New Grade:", newGrade);

                                        return axios
                                            .post('/license/updateLicenseGrade', {
                                                licenseId: licenseId,
                                                newGrade: newGrade
                                            })
                                            .then(response => {
                                                console.log("License grade updated successfully:", response.data);

                                                // Call updateUserLicenseGrade function
                                                updateUserLicenseGrade(licenseId, newGrade);
                                            })
                                            .catch(error => {
                                                console.error("Failed to update license grade:", error);
                                            });
                                    }
                                } else {
                                    const licensesToRemove = userLicenses.filter(
                                        (license) => license._userGrade === dt.oldGrade
                                    );

                                    console.log("Deleting License", licensesToRemove);
                                    const deletionPromises = licensesToRemove.map((license) => {
                                        return axios.post("/license/removeGradeFromLicense", {
                                            licenseId: license._id,
                                        });
                                    });

                                    return Promise.all(deletionPromises)
                                        .then((deletionResponses) => {
                                            console.log("Licenses deleted:", deletionResponses);
                                        })
                                        .catch((error) => {
                                            console.error("Error while deleting licenses:", error);
                                        });
                                }
                            });

                            Promise.all(updatePromises)
                                .then((responses) => {
                                    console.log("All license grades updated successfully:", responses);
                                })
                                .catch((error) => {
                                    console.error("Failed to update license grades:", error);
                                });
                        }

                        //Update license data also if grade changes
                        // if (changedGrades.length > 0) {
                        //     console.log("Get license ", userLicenses);
                        //     let licensesDt = userLicenses;

                        //     const updatePromises = changedGrades.map((dt, i) => {
                        //         if (dt.newGrade != "removed") {
                        //             const matchingLicense = licensesDt.find((license) => license._userGrade === dt.oldGrade);
                        //             if (matchingLicense) {
                        //                 const licenseId = matchingLicense._id;
                        //                 const newGrade = dt.newGrade;
                        //                 console.log("License ID:", licenseId, "New Grade:", newGrade);

                        //                 return axios.post('/license/updateLicenseGrade', {
                        //                     licenseId: licenseId,
                        //                     newGrade: newGrade
                        //                 })
                        //                     .then(response => {
                        //                         console.log("License grade updated successfully:", response.data);

                        //                         // Call updateUserLicenseGrade function
                        //                         updateUserLicenseGrade(licenseId, newGrade);
                        //                     })
                        //                     .catch(error => {
                        //                         console.error("Failed to update license grade:", error);
                        //                     });
                        //             }
                        //         }
                        //         else {
                        //             const licensesToRemove = userLicenses.filter(
                        //                 (license) => license._userGrade === dt.oldGrade
                        //             );

                        //             console.log("Deleting License", licensesToRemove);
                        //             const deletionPromises = licensesToRemove.map((license) => {
                        //                 return axios.post("/license/removeGradeFromLicense", {
                        //                     licenseId: license._id,
                        //                 });
                        //             });

                        //             try {
                        //                 const deletionResponses = await Promise.all(deletionPromises);
                        //                 console.log("Licenses deleted:", deletionResponses);
                        //             } catch (error) {
                        //                 console.error("Error while deleting licenses:", error);
                        //             }
                        //         }
                        //     });

                        //     Promise.all(updatePromises)
                        //         .then((responses) => {
                        //             console.log("All license grades updated successfully:", responses);
                        //         })
                        //         .catch((error) => {
                        //             console.error("Failed to update license grades:", error);
                        //         });
                        // }

                    }
                    setTimeout(() => {
                        props.handleCloseModal();
                    }, 1000);
                    console.log("Updated User");
                })
                .catch((err) => {

                })
        }
        else {
            setErrorMssg("Please Check all the fields")
        }
    }

    const removeGrade = async (gradeVal) => {
        console.log("Removing grade", gradeVal);

        // if (!removedGrade.includes(gradeVal)) {
        //     setRemovedGrade([...removedGrade, gradeVal])
        // }

        if (removedGrade.includes(gradeVal)) {
            // Grade already exists in removedGrades, so remove it
            const updatedRemovedGrades = removedGrade.filter((grade) => grade !== gradeVal);
            setRemovedGrade(updatedRemovedGrades);
        } else {
            // Grade doesn't exist in removedGrades, so add it
            const updatedRemovedGrades = [...removedGrade, gradeVal];
            setRemovedGrade(updatedRemovedGrades);
        }

        // const licensesToRemove = userLicenses.filter(
        //     (license) => license._userGrade === gradeVal
        // );

        // const deletionPromises = licensesToRemove.map((license) => {
        //     return axios.post("/license/removeGradeFromLicense", {
        //         licenseId: license._id,
        //     });
        // });

        // try {
        //     const deletionResponses = await Promise.all(deletionPromises);
        //     console.log("Licenses deleted:", deletionResponses);
        // } catch (error) {
        //     console.error("Error while deleting licenses:", error);
        // }
    };

    // const removeGrade = (gradeVal) => {
    //     console.log("Removing grade", gradeVal);
    //     // Find the license with the matching grade value
    //     const licenseToRemove = userLicenses.filter((license) => license._userGrade === gradeVal);

    //     if (licenseToRemove) {
    //         console.log("License deleting", licenseToRemove);
    //         axios.post('/license/removeGradeFromLicense', { licenseId: licenseToRemove._id })
    //             .then((res) => {

    //             })
    //             .catch((err) => {
    //                 console.log("Error while deleting grade");
    //             })
    //     }

    // }


    const addGrade = () => {
        // let userId = props.selectedUser._id;
        // let temp = inputField.grade;

        // console.log("added grade", temp);
        // updateUserGrade(userId, temp)
        console.log("Adding grade", grade);
        if (grade[grade.length - 1] !== "") {
            setGrade([...grade, ""])
        }
    }

    const updateGrade = (gradePos) => {
        let userId = props.selectedUser._id;
        let temp = inputField.grade;
        let filteredGrade = "";

        temp.map((dt) => {
            filteredGrade += dt + ","
        })
        updateUserGrade(userId, filteredGrade)
    }


    // const handleChangeGrade = (gradeVal, gradePos) => {
    //     console.log("gradeVal", gradeVal, "gradePos", gradePos);

    //     let gradeTemp = [];
    //     gradeTemp = grade;
    //     gradeTemp[gradePos] = gradeVal;

    //     // setGrade(gradeTemp)

    //     setGrade(prevGrade => {
    //         const updatedGrade = [...prevGrade];
    //         updatedGrade[gradePos] = gradeVal;
    //         return updatedGrade;
    //     });
    //     console.log("gradeTempgradeTemp", gradeTemp);
    //     // setGrade(gradeTemp)

    //     // setGrade(prevGrade => {
    //     //     const updatedGrade = prevGrade.map((grade, index) => {
    //     //         if (index === gradePos) {
    //     //             return gradeVal;
    //     //         }
    //     //         return grade;
    //     //     });
    //     //     return updatedGrade;
    //     // });
    // }



    // const GradeComponent = (gradeDt) => {
    //     console.log("GradeComponent", gradeDt);
    //     return <input key={gradeDt.key} className="h-40 me-3" type="text" name="grade" placeholder="Enter Grade" value={gradeDt.grade || ''} defaultValue={gradeDt.grade} onChange={(e) => {
    //         handleChangeGrade(e.target.value, gradeDt.gradePos)
    //     }} style={{ width: "55px", borderRadius: "3px", border: "1px solid lightgrey", textAlign: "center" }} />
    // }

    const handleChangeGrade = (gradeVal, gradePos) => {
        setGrade(prevGrade => {
            const updatedGrade = [...prevGrade];
            updatedGrade[gradePos] = gradeVal;
            return updatedGrade;
        });
        console.log("sssssssssssssss", changedGradesDt);

    };


    const GradeComponent = ({ gradePos, grade }) => {
        // console.log("GradeComponent", grade);
        return (
            <>
                <div className="d-flex flex-column">
                    <div className="d-flex me-3 mt-3" style={{ borderRadius: "3px", border: "1px solid lightgrey", }}>
                        <input
                            className="h-40"
                            type="text"
                            name="grade"
                            placeholder="Enter Grade"
                            defaultValue={grade}
                            // value={grade || ''}
                            onChange={(e) => {
                                handleChangeGrade(e.target.value, gradePos)
                            }}
                            disabled={removedGrade.includes(grade) ? true : false}
                            style={{ width: "55px", textAlign: "center", border: "none" }}
                        />
                        <button className={`h-40  ${removedGrade.includes(grade) ? "undo-btn" : "close-btn"}`} onClick={(e) => {
                            e.preventDefault();
                            removeGrade(grade)
                        }}>
                            ×
                        </button>
                    </div>
                    {/* <div className="txt-xsm">
                        <span>LIC: </span>
                        {
                            userLicenses.filter((dt) => dt._userGrade === grade).length
                        }
                    </div> */}
                </div>
            </>
        );
    };


    return (
        <div>
            <div className="mt-3" style={{ textAlign: "center" }}>
                <Modal backdrop="static" show={props.showModal} onHide={props.handleCloseModal}>
                    <Modal.Header>
                        <div>
                            <h4>
                                Edit User
                            </h4>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Form.Group className="d-flex" controlId="regNo">
                                <Form.Label className="w-25">Id</Form.Label>
                                <Form.Control type="text" placeholder="Enter Id" disabled defaultValue={props.selectedUser.regNum} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mt-3 d-flex" controlId="fullName">
                                <Form.Label className="w-25">Full Name</Form.Label>
                                <Form.Control type="text" name="userName" placeholder="Enter Full Name" defaultValue={props.selectedUser.userName} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mt-3 d-flex" controlId="emailId">
                                <Form.Label className="w-25">Email-ID</Form.Label>
                                <Form.Control type="text" name="userEmail" placeholder="Enter Email-ID" defaultValue={props.selectedUser.userEmail} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mt-3 d-flex" controlId="role">
                                <Form.Label className="w-25">Role</Form.Label>
                                <select className="w-100 dropdown-slct-action" name="userRole" onChange={handleChange} defaultValue={props.selectedUser.userRole} style={{ height: "40px" }}>
                                    <option value="">Select Role</option>
                                    <option value="student">Student</option>
                                    <option value="schoolAccount">School Account</option>
                                    <option value="teacher">Teacher</option>
                                    <option value="admin">Admin</option>
                                </select>
                            </Form.Group>

                            <Form.Group className="d-flex" controlId="grade">
                                <Form.Label className="w-25 mt-3 ">Grade</Form.Label>
                                <div className="w-100 d-flex align-items-start">
                                    {
                                        // grade ? <div>
                                        //     {
                                        //         props.selectedUser.userRole === "teacher" ? <div className="w-100 d-flex align-items-center justify-content-start flex-wrap">
                                        //             {
                                        //                 grade.map((dt, i) => {
                                        //                     // return <GradeComponent gradePos={i} grade={dt} />
                                        //                     return <GradeComponent key={i} gradePos={i} grade={dt} />
                                        //                 })
                                        //             }
                                        //             <button className="h-40" style={{ backgroundColor: "transparent", width: "55px", border: "1px solid lightgrey", minWidth: "55px !important", borderRadius: "3px" }} onClick={addGrade}>
                                        //                 +
                                        //             </button>
                                        //         </div>
                                        //             :
                                        //             <input className="h-40" type="text" name="grade" placeholder="Enter Grade" defaultValue={props.selectedUser.grade} onChange={handleChange} style={{ width: "55px", borderRadius: "3px", border: "1px solid lightgrey", textAlign: "center" }} />
                                        //     }
                                        // </div>
                                        //     : ""
                                    }
                                    {
                                        props.selectedUser.userRole === "teacher" ? <div >
                                            {
                                                grade !== undefined ? <div >
                                                    {
                                                        typeof (grade) === "object" ? <>
                                                            <div className="w-100 d-flex align-items-center justify-content-start flex-wrap">
                                                                {
                                                                    grade.map((dt, i) => {
                                                                        // return <GradeComponent gradePos={i} grade={dt} />
                                                                        return <GradeComponent key={i} gradePos={i} grade={dt} />
                                                                    })
                                                                    // console.log("zzzzzzzzzzzzzzz", grade)
                                                                }
                                                                <button className="h-40 mt-3" style={{ backgroundColor: "transparent", width: "55px", border: "1px solid lightgrey", minWidth: "55px !important", borderRadius: "3px" }} onClick={addGrade}>
                                                                    +
                                                                </button>
                                                            </div>
                                                            <div className="mt-2">
                                                                <h6 style={{ color: "red" }}>
                                                                    {
                                                                        new Set(grade).size !== grade.length ? "Same grade exist please check" : ""
                                                                    }
                                                                    {/* {gradeErrMsg} */}
                                                                </h6>
                                                            </div>
                                                        </> : "Loading"
                                                    }
                                                </div> : ""
                                            }
                                        </div>
                                            :
                                            <input className="h-40" type="text" name="grade" placeholder="Enter Grade" defaultValue={props.selectedUser.grade} onChange={handleChange} style={{ width: "55px", borderRadius: "3px", border: "1px solid lightgrey", textAlign: "center" }} />
                                    }
                                </div>
                            </Form.Group>
                            {/* Grade: {JSON.stringify(grade)} */}
                            <Form.Group className="mt-3 d-flex" controlId="dob">
                                <Form.Label className="w-25">DOB</Form.Label>
                                <Form.Control type="date" name="dob" defaultValue={inputField.dob} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group className="mt-3 d-flex" controlId="phoneNo">
                                <Form.Label className="w-25">Phone No.</Form.Label>
                                <Form.Control type="text" name="phoneNum" placeholder="Enter Phone No." defaultValue={props.selectedUser.phoneNum} onChange={handleChange} />
                            </Form.Group>

                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button className="cancel-btn" style={{ height: "40px" }} onClick={(e) => { props.handleCloseModal() }}>
                            Cancel
                        </button>
                        {
                            grade !== undefined ? <button disabled={new Set(grade).size !== grade.length ? true : false} className={"prim-btn"} style={{ height: "40px" }} onClick={onSave}>
                                Update
                            </button> : ""
                        }

                    </Modal.Footer>
                    {errorMssg && <h6 style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>{errorMssg}</h6>}
                </Modal>
            </div>
        </div>
    );
};

export default PopupModalAssgnedUsers;
