// import React, { useState } from 'react';
// import { Col, Row, Container } from 'react-bootstrap'
// import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
// import axios from 'axios';

// function RegisterAuthor(props) {

//     const [inputField, setInputField] = useState({
//         user_name: "",
//         user_phone_num: "",
//         user_email: "",
//         user_password: "",
//         user_role: []
//     });

//     const [isDone, setIsDone] = useState(false);

//     //taking inputs from user and assigning to respective variable
//     const onChangeTxt = (e) => {
//         setInputField({
//             ...inputField,
//             [e.target.name]: e.target.value,
//         });
//     };

//     const addRole = (e) => {
//         const dt = inputField.user_role
//         let newDt = [...inputField.user_role, e.target.value];
//         if (dt.includes(e.target.value)) {
//             newDt = newDt.filter(val => val !== e.target.value);
//         }
//         console.log(newDt);
//         setInputField({
//             ...inputField,
//             user_role: newDt
//         });
//     }

//     const onSubmit = (e) => {
//         axios.post("/authorUser/add", inputField)
//             .then((res) => {
//                 console.log("User Added");
//                 setIsDone(true)
//             }).catch((err) => {
//                 console.log("Error", err);
//             })
//     }



//     return <Container maxWidth={"xs"} >
//         {
//             isDone ? <FormLabel style={{ textAlign: "center", color: "#28a745", fontWeight: "bold", marginTop: "50px" }} component="legend">
//                 <h4>
//                     You have successfully registered.<br />
//                     Admin will verify your account soon!
//                 </h4>
//             </FormLabel>
//                 :
//                 <Row>
//                     <Col xs={12} md={6} className='m-auto mt-5'>
//                         <Grid container direction="column" rowSpacing={3} columnSpacing={{ xs: 5, sm: 2, md: 3 }}>
//                             <Grid item xs={12} style={{ textAlign: "center" }}>
//                                 <label style={{ fontWeight: "600", fontSize: "1.5rem", marginTop: "40px" }}>Register with LM Content Team</label>
//                             </Grid>
//                             <Grid item xs={12} alignItems="center" justifyContent="center">
//                                 <TextField size="medium" name="user_name" id="outlined-basic" label="FULL NAME" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
//                             </Grid>
//                             <Grid item xs={12} alignItems="center" justifyContent="center">
//                                 <TextField size="medium" name="user_phone_num" id="outlined-basic" label="PHONE NO." variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
//                             </Grid>
//                             <Grid item xs={12} alignItems="center" justifyContent="center">
//                                 <TextField size="medium" name="user_email" id="outlined-basic" label="EMAIL" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
//                             </Grid>
//                             <Grid item xs={12} alignItems="center" justifyContent="center">
//                                 <FormGroup row onChange={addRole}>
//                                     <FormLabel component="legend">Select your role</FormLabel>
//                                     <FormControlLabel control={<Checkbox />} value={"author"} label="Author" />
//                                     <FormControlLabel control={<Checkbox />} value={"tester"} label="Tester" />
//                                     <FormControlLabel control={<Checkbox />} value={"reviewer"} label="Reviewer" />
//                                     {/* <FormControlLabel control={<Checkbox />} value={"approver"} label="Approver" /> */}
//                                     {/* <FormControlLabel control={<Checkbox />} value={"supervisor"} label="Supervisor" /> */}
//                                 </FormGroup>
//                             </Grid>
//                             <Grid item xs={12} alignItems="center" justifyContent="center">
//                                 <TextField size="medium" name="user_password" id="outlined-basic" type="password" label="PASSWORD" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
//                             </Grid>
//                             <Grid item xs={12} container alignItems="center" justifyContent="center">
//                                 <Button variant={"contained"} onClick={onSubmit} style={{ width: "200px", height: "50px", fontWeight: "bold", fontSize: "1rem" }}> REGISTER </Button>
//                             </Grid>
//                         </Grid>
//                     </Col>
//                 </Row>
//         }

//     </Container>
// }

// export default RegisterAuthor;





import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, FormGroup, FormLabel } from '@mui/material';
import { Col, Row, Container } from 'react-bootstrap';
import axios from 'axios';

const RegisterAuthor = () => {
    const [isDone, setIsDone] = useState(false);

    function validateEmail(value) {
        let error;
        if (!value) {
            error = 'Please enter email address';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = 'Invalid email address';
        }
        return error;
    }

    function validateFullname(value) {
        let error;
        if (!value) {
            error = 'Please enter full name';
        } else if (!/^[A-Za-z ]+$/i.test(value)) {
            error = 'Only alphabets are allowed';
        }
        return error;
    }

    function validatePhoneNumber(value) {
        let error;
        if (!value) {
            error = 'Please enter a phone number';
        } else if (!/^[0-9]+$/i.test(value)) {
            error = 'Only numbers are allowed';
        } else if (value.length < 10 || value.length > 10) {
            error = 'Phone number must be exactly 10 digits';
        }
        return error;
    }

    function validateRole(value) {
        let error;
        if (!value || value.length === 0) {
            error = 'Please select a Role';
        }
        return error;
    }

    function validatePassword(value) {
        let error;
        if (!value) {
            error = 'Please enter password';
        }
        return error;
    }

    const FieldLevelValidationExample = () => (
        <Container maxWidth={"xs"}>
            <Row>
                {
                    isDone ? <FormLabel style={{ textAlign: "center", color: "#28a745", fontWeight: "bold", marginTop: "50px" }} component="legend">
                        <h4>
                            You have successfully registered.<br />
                            Admin will verify your account soon!
                        </h4>
                    </FormLabel>
                        :
                        <div style={{ textAlign: "center", marginTop: "10vh" }}>
                            <label style={{ fontWeight: '600', fontSize: '1.5rem', marginTop: '40px' }}>Register with LM Content Team</label>
                            <Formik
                                initialValues={{
                                    user_name: '',
                                    user_phone_num: '',
                                    user_email: '',
                                    user_password: '',
                                    user_role: []
                                }}
                                onSubmit={values => {
                                    console.log("values", values);
                                    axios.post("/authorUser/add", values)
                                        .then((res) => {
                                            console.log("User Added", res);
                                            setIsDone(true)
                                        }).catch((err) => {
                                            console.log("Error", err);
                                        })
                                }}
                            >
                                {({ errors, touched }) => (
                                    <Form className='mt-3'>
                                        <Row>
                                            <Col xs={12} md={6} className="m-auto mt-2">
                                                <Row>

                                                    <Col xs={12} className={"mt-3"} >
                                                        <Field name="user_name" validate={validateFullname} placeholder="FULL NAME" style={{ width: '70%', minHeight: "40px" }} />
                                                        {errors.user_name && touched.user_name && <div style={{ color: "red" }}><b>{errors.user_name}</b></div>}
                                                    </Col>

                                                    <Col xs={12} className={"mt-3"}>
                                                        <Field name="user_phone_num" validate={validatePhoneNumber} placeholder="PHONE NUMBER" style={{ width: '70%', minHeight: "40px" }} />
                                                        {errors.user_phone_num && touched.user_phone_num && <div style={{ color: "red" }}><b>{errors.user_phone_num}</b></div>}
                                                    </Col>

                                                    <Col xs={12} className={"mt-3"}>
                                                        <Field name="user_email" validate={validateEmail} placeholder="EMAIL" style={{ width: '70%', minHeight: "40px" }} />
                                                        {errors.user_email && touched.user_email && <div style={{ color: "red" }}><b>{errors.user_email}</b></div>}
                                                    </Col>

                                                    <Col xs={12} className={"mt-3"}>
                                                        <Field name="user_password" type="password" validate={validatePassword} placeholder="PASSWORD" style={{ width: '70%', minHeight: "40px" }} />
                                                        {errors.user_password && touched.user_password && <div style={{ color: "red" }}><b>{errors.user_password}</b></div>}
                                                    </Col>

                                                    <Col xs={12} className={"mt-3"}>
                                                        <div className='m-auto' style={{ width: "70%" }}>
                                                            <div style={{ textAlign: "start" }}>
                                                                <FormLabel>Please Select your role</FormLabel>
                                                            </div>

                                                            <div className='d-flex flex-wrap'>
                                                                <div className='me-3'>
                                                                    <Field type="checkbox" name="user_role" value="author" validate={validateRole} />
                                                                    <label style={{ marginLeft: "5px" }}>Author</label>
                                                                </div>

                                                                <div className='me-3'>
                                                                    <Field type="checkbox" name="user_role" value="tester" validate={validateRole} />
                                                                    <label style={{ marginLeft: "5px" }}>Tester</label>
                                                                </div>

                                                                <div className='me-3'>
                                                                    <Field type="checkbox" name="user_role" value="reviewer" validate={validateRole} />
                                                                    <label style={{ marginLeft: "5px" }}>Reviewer</label>
                                                                </div>

                                                                <div className='me-3'>
                                                                    <Field type="checkbox" name="user_role" value="approver" validate={validateRole} />
                                                                    <label style={{ marginLeft: "5px" }}>Approver</label>
                                                                </div>

                                                                <div className='me-3'>
                                                                    <Field type="checkbox" name="user_role" value="supervisor" validate={validateRole} />
                                                                    <label style={{ marginLeft: "5px" }}>Supervisor</label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        {errors.role && touched.role && <div style={{ color: "red" }}><b>{errors.role}</b></div>}
                                                    </Col>

                                                </Row>
                                            </Col>
                                        </Row>
                                        <button className='mt-4 btn-latest' style={{ width: "200px", height: "40px", fontWeight: "bold", }} type="submit">Register</button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                }
            </Row>
        </Container>
    );

    return <FieldLevelValidationExample />;
}

export default RegisterAuthor;
