import axios from 'axios';
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

export default function DeletePopup(props) {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [confirmValue, setConfirmValue] = useState("");

    const handleShow = () => props.setShow(true);

    const handleClose = () => {
        setConfirmValue("");
        props.setShow(false);
    };

    const onDeleteUserClick = (e) => {
        e.preventDefault();
        let payload = {
            userId: props.selectedUser._id,
        }
        axios.post('/lmAccountsUser/deleteUser', payload)
            .then((res) => {
                setMessage(res.data.message)
                setTimeout(() => {
                    handleClose()
                }, 2000);
            })
            .catch((err) => {
                setTimeout(() => {
                    handleClose()
                }, 2000);
                setMessage("Please Try again, Server Error")
            })
    }

    const handleInputChange = (e) => {
        setConfirmValue(e.target.value);
    };

    const isConfirmValueValid = confirmValue === props.selectedUser.userName;

    return (
        <div>
            <Modal show={props.show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header >
                    <Modal.Title> Delete </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label>Are you sure you want to delete user: <b> {props.selectedUser.userName} </b></label>
                    </div>
                    <div className='mt-2'>
                        <input placeholder='Please enter your username to confirm delete' style={{ width: "400px" }} value={confirmValue} onChange={handleInputChange} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="primry-txt-btn" onClick={handleClose}>
                        Cancel
                    </button>
                    <button className="btn-latest" onClick={onDeleteUserClick} disabled={!isConfirmValueValid}>
                        Delete
                    </button>
                </Modal.Footer>
                <div>
                    <h6><b> {message} </b></h6>
                </div>
            </Modal>
        </div>
    )
}
