import React, { useState, useCallback, useEffect } from 'react'
import useGlobalData from '../../../Zustand/GlobalData'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Col, Container, Modal, Row, Table } from 'react-bootstrap';
import AddSchoolUser from './AddSchoolUser';
import PaginationCompnt from '../../CmnCompnts/PaginationCompnt';
import NotificationPopup from '../../CmnCompnts/NotificationPopup';
import editBtnImg from "../../Assets/edit.svg";
import deleteBtnImg from "../../Assets/delete.svg";
import AddSingleUser from './AddSingleUser';
import PopupModalAssgnedUsers from './PopupModalAssgnedUsers';
import { CSVLink } from 'react-csv';
import DeletePopup from './DeletePopup';
import SearchBar from '../../CmnCompnts/SearchBar';
import ResetBtnImg from '../../Assets/reset.svg'
import ReportsData from './ReportsData';
import ReportsDataNew from './ReportsDataNew';
import SessionReport from './SessionReport';

export default function SchoolUsers(props) {

  const { InitialUpdateAccountsData, accountId, usersData, schoolData, updateUsersData } = useGlobalData();
  const [crntData, SetCrntData] = useState("student");
  const [addedUsersData, setAddedUsersData] = useState([])
  const [gradesList, setGradesList] = useState([])
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sendEmailNotification, setsendEmailNotification] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("")
  const [tab, setTab] = useState('users')

  const [currentPage, setCurrentPage] = useState(1);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [resetVal, setReset] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [reportsData, setReportsData] = useState([]);
  const [sessionData, setSessionData] = useState([]);

  // const [roleSelectedVal, setRoleSelectedVal] = useState("")
  // const [gradesSelectedVal, setGradesSelectedVal] = useState("")

  const [slctdRole, setSlctdRole] = useState("")
  const [slctdGrade, setSelectedGrade] = useState("")

  const roles = [
    { label: "All", value: "all" },
    { label: "Teachers", value: "teacher" },
    { label: "Students", value: "student" },
    { label: "Admins", value: "admin" },
    { label: "School Account", value: "schoolAccount" },
  ];

  const [showMsg, setShowMsg] = useState({
    message: "",
    type: ""
  });

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChangeTabs = (event) => {
    const id = event.target.id;
    if (id === 'users') {
      setTab('users');
      setShowReports(false);
    } else if (id === 'report') {
      setTab('report');
      setShowReports(true);
    } else if (id === 'session') {
      setTab('session');
    }
  };

  // const handleEditUser = (user, index) => {
  const handleEditUser = (user) => {
    setSelectedUser(user);
    setRole(user.userRole);
    setShowModal(true);
  };

  useEffect(() => {
    setFilteredUsers(usersData)
    setFilteredSearchData(usersData)
    let grades = []
    usersData.map((dt, i) => {
      let gradeTemp = dt.grade.split(",");
      gradeTemp.map((gradeDt, k) => {
        if (!grades.includes(gradeDt)) {
          grades.push(gradeDt)
        }
      })
    })
    setGradesList(grades)
    // console.log("zzzzzzzzzzzzzzzzzzzz", grades);
  }, [schoolData, usersData])

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const showClikdData = (e) => {
    SetCrntData(e.target.id)
  }



  useEffect(() => {
    filterMultipleFields();
  }, [slctdGrade, slctdRole, searchTxt])


  const filterUsersOnRole = (roleVal) => {
    if (roleVal.toLowerCase() == "all") {
      setSlctdRole("")
    }
    else {
      setSlctdRole(roleVal)
    }
  };


  const filterByGrade = (gradeVal) => {
    if (gradeVal.toLowerCase() == "all") {
      setSelectedGrade("")
    }
    else {
      setSelectedGrade(gradeVal)
    }
  }

  const filterMultipleFields = () => {
    console.log("Ssssssssssss", slctdGrade, "--", slctdRole);

    let searchItems = ["userName", "userEmail", "pin", "regNum", "key", "phoneNum"]

    let searchDt = usersData.filter((dt) => {
      return searchItems.some((item) => {
        return dt[item].toLowerCase().includes(searchTxt.toLowerCase());
      });
    })

    let temp = searchDt;

    if (slctdGrade && slctdRole) {
      temp = searchDt.filter((dt) => dt.grade.includes(slctdGrade) && dt.userRole.toLowerCase() === slctdRole.toLowerCase());
    } else if (slctdGrade) {
      temp = searchDt.filter((dt) => dt.grade.includes(slctdGrade));
    } else if (slctdRole) {
      temp = searchDt.filter((dt) => dt.userRole.toLowerCase() === slctdRole.toLowerCase());
    }

    setFilteredUsers(temp);
  };


  // Pagination logic for 10 rows in a page.
  const rowsPerPage = 10; // Number of rows to display on the first page
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedUsers = filteredUsers.slice(startIndex, endIndex);



  const onSendEmailClick = (dt) => {
    let payload = {
      userName: dt.userName,
      userEmail: dt.userEmail,
      pinNum: dt.key,
      pin: dt.pin,
      regNum: dt.regNum,
      schoolName: schoolData.schoolName,
    }
    axios.post("/lmAccountsUser/sendEmail", payload)
      .then((res) => {
        console.log("Send Email ", res.data);
        setsendEmailNotification(true)
      })
      .catch((err) => {

      })
  }


  return (
    <div>
      <Container>
        <Row style={{ display: 'flex', textAlign: "center" }}>
          <Col md={12} style={{ textAlign: "start", margin: "0px auto", height: "115px" }}>
           {tab ==='users' && (<>
            <h5 className='mt-4'><b>Assigned Users </b></h5>
            <div className='mt-4' style={{ border: "1px solid lightgray", padding: "15px", borderRadius: "12px" }}>
              <Row>
                <Col xs={12} md={4}>
                  <div>
                    <h6><b>Search</b></h6>
                    <div className='mt-3'>
                      <SearchBar setSearchTxt={setSearchTxt} searchTxt={searchTxt} searchData={usersData} searchItems={["userName", "userEmail", "pin", "regNum", "key", "phoneNum"]} setFilteredDt={setFilteredUsers} setFilteredSearchData={setFilteredSearchData} resetVal={resetVal} setReset={setReset} />
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={8}>
                  <div>
                    <h6><b>Filter</b></h6>
                    <div className='mt-3 d-flex align-items-center'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label style={{ marginRight: '8px' }}>
                          <b>Roles</b>
                        </label>
                        <select value={slctdRole === "" ? "ALL" : slctdRole} style={{ width: "150px" }} className='ms-2 dropdown-slct-action h-45' onChange={(event) => filterUsersOnRole(event.target.value)}>
                          {/* <option value={""}>Select Role</option> */}
                          {roles.map((role) => (
                            <option key={role.value} value={role.value}>
                              {role.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='ms-4' style={{ display: 'flex', alignItems: 'center' }}>
                        <label style={{ marginRight: '8px' }}>
                          <b>Grades</b>
                        </label>
                        {/* <select style={{ width: "150px" }} className='ms-2 dropdown-slct-action h-45' onChange={filterUsersOnGrade}> */}
                        <select value={slctdGrade === "" ? "ALL" : slctdGrade} style={{ width: "150px" }} className='ms-2 dropdown-slct-action h-45' onChange={(e) => filterByGrade(e.target.value)}>
                          <option value={"all"}>All</option>
                          {
                            gradesList.length > 0 ? <>
                              {
                                gradesList.map((dt, i) => {
                                  return <option value={dt}>
                                    {dt}
                                  </option>
                                })
                              }
                            </> : "No grades found"
                          }
                        </select>
                      </div>
                      <div >
                        <button className='ms-3 h-45 tertiary-btn' onClick={(e) => {
                          e.preventDefault();
                          setSearchTxt("")
                          setSlctdRole("")
                          setSelectedGrade("")
                          setReset(true)
                        }}>
                          <img src={ResetBtnImg} height={"20px"} width={"auto"} />
                          <label className='ms-2 txt-underline'>Reset</label>
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            </>)}
            <Row>
              <Col className='mt-3' style={{ textAlign: "end" }} >
                <div className='d-flex align-items-center justify-content-start'>
                  <button onClick={handleChangeTabs} id='users' type='button' className={'ms-3   d-flex align-items-center justify-content-center' + (tab === "users" ? " btn-latest" : " primry-txt-btn")} style={{ textDecoration: "none" }} >
                    Users
                  </button>
                  <button onClick={handleChangeTabs} id='report' type='button' className={'ms-3   d-flex align-items-center justify-content-center' + (tab === "report" ? " btn-latest" : " primry-txt-btn")} style={{ textDecoration: "none" }}>
                    Reports
                  </button>
                  <button onClick={handleChangeTabs} id='session' type='button' className={'ms-3   d-flex align-items-center justify-content-center' + (tab === "session" ? " btn-latest" : " primry-txt-btn")} style={{ textDecoration: "none" }}>
                    Session Reports
                  </button>
                </div>
              </Col>

              <Col style={{ textAlign: "start" }} >
                <div className='d-flex align-items-center justify-content-end'>
                  {console.log("usersData", usersData)}
                  {tab === 'users' && (
                    <CSVLink className='ms-3 btn-latest d-flex align-items-center justify-content-center' style={{ textDecoration: "none" }} data={usersData} filename='lm_users_data.csv'>
                      Export CSV
                    </CSVLink>
                  )}
                  {console.log("reportsData", reportsData)}
                  {tab === 'report' && (
                    < CSVLink className='ms-3 mt-3 btn-latest d-flex align-items-center justify-content-center' style={{ textDecoration: "none" }} data={reportsData} filename='lm_reports_data.csv'>
                      Export Reports CSV
                    </CSVLink>
                  )}
                   {console.log("sessionData", sessionData)}
                  {tab === 'session' && (
                    < CSVLink className='ms-3 mt-3 btn-latest d-flex align-items-center justify-content-center' style={{ textDecoration: "none" }} data={sessionData} filename='lm_session_data.csv'>
                      Export Session CSV
                    </CSVLink>
                  )}
                  <div className='ms-3'>
                    {tab === 'users' ? <AddSingleUser /> : ""}
                  </div>
                </div>
              </Col>
            </Row>
            <div>
              {tab === 'users' && (
                filteredUsers.length > 0 ? (
                  <div style={{ width: '100%' }}>
                    <Row>
                      <Col xs={12} className='m-auto'>
                        <Table bordered responsive hover style={{ backgroundColor: "white" }}>
                          <thead>
                            <tr>
                              <th style={{ textTransform: "capitalize" }}>{slctdRole} Id's</th>
                              <th>Full Name</th>
                              <th>Email-ID</th>
                              <th>Role</th>
                              <th>Grade</th>
                              <th>DOB</th>
                              <th>PIN</th>
                              <th>OTP</th>
                              <th>Phone No.</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsers.length > 0 ? (
                              displayedUsers.map((dt, i) => (
                                <tr key={i}>
                                  <td>{dt.regNum}</td>
                                  <td>{dt.userName}</td>
                                  <td>{dt.userEmail ? dt.userEmail : "-"}</td>
                                  <td>{dt.userRole}</td>
                                  <td>
                                    <div style={{ display: "flex", flexWrap: "wrap", height: "100%" }}>
                                      {dt.grade}
                                    </div>
                                  </td>
                                  <td>{dt.dob}</td>
                                  <td>{dt.pin}</td>
                                  <td>{dt.key}</td>
                                  <td>{dt.phoneNum ? dt.phoneNum : "-"}</td>
                                  <td>
                                    <div className='d-flex align-items-center justify-content-around'>
                                      <div style={{ textAlign: "right" }}>
                                        <button className="edit-button" onClick={(e) => {
                                          e.preventDefault();
                                          handleEditUser(dt);
                                        }}>
                                          <img style={{ cursor: "pointer" }} src={editBtnImg} width="20px" height="auto" alt="Edit" />
                                        </button>
                                      </div>
                                      <button className='delete-button ms-2' onClick={(e) => {
                                        e.preventDefault();
                                        setShowDeleteModal(true);
                                        setSelectedUser(dt);
                                      }}>
                                        <img src={deleteBtnImg} width="20px" height="auto" style={{ cursor: "pointer" }} alt="Delete" />
                                      </button>
                                      <button className='sec-btn ms-2' style={{ minWidth: "130px" }} onClick={(e) => {
                                        e.preventDefault();
                                        onSendEmailClick(dt);
                                      }}>
                                        Send Email
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : "Loading"}
                          </tbody>
                        </Table>
                        <PopupModalAssgnedUsers showModal={showModal} handleCloseModal={() => setShowModal(false)} selectedUser={selectedUser} role={slctdRole} users={users} setUsers={setUsers} handleEditUser={handleEditUser} />
                        <DeletePopup show={showDeleteModal} setShow={setShowDeleteModal} selectedUser={selectedUser} />
                      </Col>
                    </Row>
                    <div>
                      <PaginationCompnt currentPage={currentPage} setCurrentPage={setCurrentPage} totalData={filteredUsers.length} rowsPerPage={rowsPerPage} />
                    </div>
                  </div>
                ) : (
                  <div>
                    <h6 className='p-2 text-center mt-4'>
                      No Data Found
                    </h6>
                  </div>
                )
              )}
              {tab === 'report' && <ReportsDataNew setReports={setReportsData}/> }
              {tab === 'session' && <SessionReport setReports={setSessionData}/> }
            </div>
            {tab === 'users' && (<>
              <div style={{ marginTop: "10vh", textAlign: "center", color: "red" }}>
              {displayMessage ? displayMessage : ""}
            </div>
            <div style={{ borderTop: "1px solid lightgray" }}>
              <h5 className='text-center mt-3'><b>To Add More Users</b></h5>
              <div className='mt-3'>
                <AddSchoolUser />
              </div>
            </div>
            </>)}
            <div>
              {
                sendEmailNotification ? <NotificationPopup setShowMsg={setShowMsg} type={"success"} message={"Successfully sent email"} setShow={setsendEmailNotification} timer={2000} /> : ""
              }
            </div>
          </Col>
        </Row>
      </Container >
    </div >
  )
}

