import React, { useEffect, useState } from 'react'
import SchoolsList from '../../CmnCompnts/SchoolsList'
import { Col, Row } from 'react-bootstrap'
import NavBar from '../../navBar'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import useGlobalData from '../../../Zustand/GlobalData'

export default function SelectSchool() {
    const [slctSchool, setSlctSchool] = useState([])
    const [errorMsg, setErrorMsg] = useState("")
    const navigate = useNavigate();
    const { addAccountId } = useGlobalData();


    const onSchoolClick = () => {

    }

    const handleSelectClick = (e) => {
        e.preventDefault();
        if (slctSchool && slctSchool.id) {
            localStorage.setItem("_accountId", slctSchool.id);
            addAccountId(slctSchool.id);
            navigate("/schoolDashboard/users", {
                state: {
                    schoolData: slctSchool,
                },
            });
        }
    };

    return (
        <div>
            {/* <div>
                <NavBar />
            </div> */}
            <Row>
                <Col xs={6} className='m-auto mt-5'>
                    <div className='mb-2 pb-2'>
                        <h4><b>Select School</b></h4>
                    </div>
                    <div className='d-flex '>
                        <div className='w-100'>
                            <SchoolsList
                                setSlctSchool={setSlctSchool}
                                onSchoolClick={onSchoolClick}
                            />
                        </div>
                        <button
                            className="btn-latest ms-2"
                            style={{ minHeight: "35px" }}
                            onClick={handleSelectClick}
                            disabled={!slctSchool || !slctSchool.id}
                        >
                            Select
                        </button>
                    </div>
                </Col>
            </Row>
            <Row>
            </Row>
        </div>
    )
}
