import React, { useEffect } from 'react'

export default function NotificationPopup(props) {

    // useEffect(() => {
    //     setTimeout(() => {

    //     }, 3000);
    // }, [])
    useEffect(() => {
        setTimeout(() => {
            props.setShowMsg({
                message: "",
                type: ""
            })
            props.setShow(false)
        }, props.timer);
    }, [])

    const getTextColor = () => {
        if (props.type === "warning" || props.type === "error") {
            return "red"
        }
        if (props.type === "success") {
            return "green"
        }
    }

    return (
        <div className={`animate__animated animate__fadeInDown notification-popup`} style={{ color: getTextColor() }}>
            {
                props.message
            }
        </div>
    )
}
