import { React, useContext, useEffect, useState } from 'react';
import LogoImage from './Assets/logo.png';
import { useNavigate } from 'react-router-dom';
import { Container, Navbar, NavbarBrand } from 'react-bootstrap';
import { UserContext } from './Context/Reducer';
import logoutImg from './Assets/logout.svg'
import SelectActionCmnt from './Admin/SelectActionCmnt';
// import { UserContext } from '../App';
import LogoutImg from '../components/Assets/logout.svg';
import ChangeAndUpdatePassword from './Admin/PasswordCompnts/ChangePassword';
import useGlobalData from '../Zustand/GlobalData';
import DashboardBtnImg from '../components/Assets/dashboard.svg';

function NavBarPage() {
    let navigate = useNavigate();

    const [isItAdmin, setIsItAdmin] = useState(false)
    const [show, setshow] = useState(false);
    const { env, addEnv } = useGlobalData();

    const goHome = () => {
        navigate("/adminDashboard")
    }

    const { state, dispatch } = useContext(UserContext) //context dt

    useEffect(() => {
        console.log("zzzzzzzzzzzzzzzz", state);

    }, [])

    const onLogout = (e) => {
        e.preventDefault();
        localStorage.setItem("UserEmail", "")
        localStorage.setItem("lmAccountId", "")
        navigate('/login')
    }

    return (
        <Navbar className='fixed-top' style={{ height: "7vh", borderBottom: "1px solid #cacaca", backgroundColor: "white" }} >
            <Container fluid>
                <Navbar.Brand onClick={goHome}>
                    <div className='d-flex align-items-center'>
                        <img src={LogoImage} style={{ width: "100px", cursor: "pointer" }} alt="Logo"  ></img>
                        {/* <h6 className='ms-3'><b>{env}</b></h6> */}
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        {
                            state.userName ? <div>
                                <span className='me-3'>Signed in as: <a href="#login">{state.userName}</a></span>
                                <span >
                                    <img src={logoutImg} height={"30px"} width={"30px"} onClick={onLogout} style={{ cusrsor: "pointer" }} />
                                </span>
                            </div> : ""
                        }
                    </Navbar.Text>
                    <Navbar.Text>
                        <div className='d-flex'>
                            {/* <SelectActionCmnt /> */}
                            <button className='btn-latest me-2' onClick={(e) => navigate('/adminDashboard')}>
                                <img style={{ color: "white" }} src={DashboardBtnImg} /> Main Dashboard
                            </button>
                            <button className='btn-latest me-2' onClick={(e) => {
                                e.preventDefault();
                                setshow(true)
                            }}>
                                Change Password
                            </button>
                            <button className='ms-2' style={{ backgroundColor: "transparent", color: "red", border: "none" }} onClick={(e) => {
                                e.preventDefault();
                                localStorage.setItem("isAdminLogged", false)
                                navigate('/adminlogin')
                            }}>
                                <img src={logoutImg} width={"30px"} />
                            </button>

                        </div>
                    </Navbar.Text>
                </Navbar.Collapse>
                <div>
                    <ChangeAndUpdatePassword show={show} setShow={setshow} actionType={"changePassword"} />
                </div>
            </Container>
        </Navbar>
    )
}

export default NavBarPage;