import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../../CSSFiles/common.css"
import useGlobalData from "../../../Zustand/GlobalData";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { colors } from "@mui/material";

const AddSingleUser = () => {
    const { updateUsersData, usersData, accountId, schoolData } = useGlobalData();
    const [showModal, setShowModal] = useState(false);
    const [errorMssg, setErrorMssg] = useState("")
    const [role, setRole] = useState("");
    const [isAllFieldValidated, setAllFieldValidated] = useState(true);
    const [inputField, setInputField] = useState({
        regNo: "",
        fullName: "",
        lastName: "",
        emailId: "",
        role: [],
        grade: "",
        dob: "",
        phoneNo: "",
    });

    const navigate = useNavigate()


    const handleChange = (e) => {
        const { name, value } = e.target;
        let gradesDt = inputField.grade ? inputField.grade.split(",") : ""
        let isMultipleGradesfound = false;


        if (name === "role") {
            setRole(value);
            if (value === "teacher") {
                isMultipleGradesfound = false;
            } else {
                if (gradesDt.length > 1) {
                    isMultipleGradesfound = true;
                } else {
                    isMultipleGradesfound = false;
                }
            }
            console.log("=============", isMultipleGradesfound);
            if (isMultipleGradesfound) {
                setErrorMssg("Please Check Grades, Multiple grades are not allowed");
                setAllFieldValidated(true)
            }
            else {
                setErrorMssg("")
                setAllFieldValidated(false)
            }
        } else if (name === "fullName") {
            if (value === "" || /^[a-zA-Z]+$/.test(value)) {
                setInputField((prevInputField) => ({
                    ...prevInputField,
                    [name]: value,
                }));
                setErrorMssg("");
                setAllFieldValidated(false)
            } else {
                setErrorMssg("Only alphabets are allowed");
                setAllFieldValidated(true)
            }
        } else if (name === "lastName") {
            if (value === "" || /^[a-zA-Z]+$/.test(value)) {
                setInputField((prevInputField) => ({
                    ...prevInputField,
                    [name]: value,
                }));
                setErrorMssg("");
                setAllFieldValidated(false)
            } else {
                setErrorMssg("Only alphabets are allowed");
                setAllFieldValidated(true)
            }
        } else if (name === "regNo") {
            console.log("inputField.regNo", inputField.regNo);
            setInputField((prevInputField) => ({
                ...prevInputField,
                [name]: value,
            }));
            setErrorMssg("");
            setAllFieldValidated(false)
        } else if (name === "emailId") {
            console.log("inputField.emailId", inputField.emailId);
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setInputField((prevInputField) => ({
                ...prevInputField,
                [name]: value,
            }));
            if (emailRegex.test(value)) {
                setErrorMssg("");
                setAllFieldValidated(false)
            } else {
                setErrorMssg("Invalid email address");
                setAllFieldValidated(true)
            }
        } else if (name === "grade") {
            if (/^[a-zA-Z0-9 ,]*$/.test(value)) {
                setInputField((prevInputField) => ({
                    ...prevInputField,
                    [name]: value,
                }));
                setErrorMssg("");
                setAllFieldValidated(false)
            } else {
                setErrorMssg("Invalid characters entered in Grade");
                setAllFieldValidated(true)
            }
        } else if (name === "dob") {
            setInputField((prevInputField) => ({
                ...prevInputField,
                [name]: value,
            }));
            setErrorMssg("");
            setAllFieldValidated(false)
        } else if (name === "phoneNo") {
            if (/^\d{10}$/.test(value)) {
                setInputField((prevInputField) => ({
                    ...prevInputField,
                    [name]: value,
                }));
                setErrorMssg("");
                setAllFieldValidated(false);
            } else {
                setErrorMssg("Phone Number must contain exactly 10 digits");
                setAllFieldValidated(true);
            }
        }
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setInputField("")
        setErrorMssg("")
    };


    const handleSaveModal = async () => {
        if (errorMssg) {
            setShowModal(false);
        } else {
            const userDtWithFields = {
                regNum: inputField.regNo,
                firstname: inputField.fullName,
                lastname: inputField.lastName,
                userEmail: inputField.emailId,
                userRole: role,
                grade: inputField.grade,
                dob: inputField.dob,
                phoneNum: inputField.phoneNo,
            };

            let payload = {
                _id: accountId,
                schoolData: schoolData,
                addThisUsersDt: [userDtWithFields],
                updateGlobalData: true,
            };

            console.log("payload", payload);

            let checkRegNumExist = false;

            try {
                const res = await axios.post("/lmAccountsUser/checkRegNumExist", {
                    regNum: inputField.regNo,
                    accountId: accountId
                });
                checkRegNumExist = res.data.isRegNumExist;
            } catch (err) {
                console.log("Error", err);
            }

            if (checkRegNumExist) {
                setErrorMssg("Registration number already exists");
            } else {
                try {
                    const res = await axios.post(
                        "/lmAccountsUser/addAccountUsersByAdmin",
                        payload
                    );

                    updateUsersData(res.data.createdUsers)
                    setErrorMssg("Added Successfully");
                    setTimeout(() => {
                        setShowModal(false);
                        // navigate(0);
                    }, 2000);
                } catch (err) {
                    console.log("Error", err);
                }
            }
        }
    };

    const onSave = () => {
        if (inputField.regNo === "") {
            setErrorMssg("Please enter Reg No.");
            return false;
        }
        if (inputField.fullName === "") {
            setErrorMssg("Please enter Full Name");
            return false;
        }
        if (inputField.lastName === "") {
            setErrorMssg("Please enter Last Name");
            return false;
        }
        if (role === "") {
            setErrorMssg("Please select a Role");
            return false;
        }
        if (inputField.grade === "") {
            setErrorMssg("Please enter Grade");
            return false;
        }
        if (inputField.dob === "") {
            setErrorMssg("Please select DOB");
            return false;
        }
        if (isAllFieldValidated) {
            setErrorMssg("Please enter proper format.")
            return false;
        }
        // if (inputField.phoneNo === "") {
        //     setErrorMssg("Please enter Phone No.");
        //     return false;
        // }
        return true;
    };

    return (
        <div className="mt-3">
            <div style={{ textAlign: "right" }}>
                <button className="btn-latest" onClick={handleShowModal}>Add User</button>
            </div>
            <div className="mt-3" style={{ textAlign: "center" }}>
                <Modal backdrop="static" show={showModal} onHide={handleCloseModal}>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="regNo">
                                <Form.Label>Reg No.</Form.Label>
                                <Form.Control name="regNo" type="text" placeholder="Enter Reg No." onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mt-2" controlId="fullName">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control name="fullName" type="text" placeholder="Enter Full Name" onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mt-2" controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control name="lastName" type="text" placeholder="Enter Last Name" onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mt-2" controlId="emailId">
                                <Form.Label>Email-ID</Form.Label>
                                <Form.Control name="emailId" type="text" placeholder="Enter Email-ID" onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mt-2" controlId="role">
                                <Form.Label>Role</Form.Label>
                                <select className="dropdown-slct-action" name="role" onChange={handleChange}>
                                    <option value="">Select Role</option>
                                    <option value="student">Student</option>
                                    <option value="schoolAccount">School Account</option>
                                    <option value="teacher">Teacher</option>
                                    <option value="admin">Admin</option>
                                </select>
                            </Form.Group>
                            <Form.Group className="mt-2" controlId="grade">
                                <Form.Label>Grade</Form.Label>
                                <Form.Control name="grade" type="text" placeholder="Enter Grade" onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mt-2" controlId="dob">
                                <Form.Label>DOB</Form.Label>
                                <Form.Control name="dob" type="date" onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mt-2" controlId="phoneNo">
                                <Form.Label>Phone No.</Form.Label>
                                <Form.Control name="phoneNo" type="text" placeholder="Enter Phone No." onChange={handleChange} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="cancel-btn h-40" onClick={handleCloseModal}>
                            Cancel
                        </button>
                        <button
                            className="prim-btn h-40"
                            onClick={() => {
                                if (onSave()) {
                                    handleSaveModal();
                                }
                            }}
                        >
                            Add
                        </button>
                    </Modal.Footer>
                    {errorMssg && <h6 style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>{errorMssg}</h6>}
                </Modal>
            </div>
        </div >
    );
};

export default AddSingleUser;
