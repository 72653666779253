import axios from 'axios';
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PrimaryTextBox from '../../../CmnCompnts/TextBox';
import useGlobalData from '../../../../Zustand/GlobalData';

export default function CreateLicense() {
    const [stepsCompleted, setStepsCompleted] = useState(1);
    const [licenseError, setLicenseError] = useState("")
    const [noOfUnitsError, setNoOfUnitsError] = useState("")

    const { accountId, createLicense } = useGlobalData()

    const [inputField, setInputField] = useState({
        liscenceCategory: "paid",
        liscenceType: "Subscription",
        totalUnits: 0,
        liscencePeriod: "",
        liscenceFrequency: "",
    });
    const [error, setError] = useState("");

    const [apiResponse, setApiResponse] = useState("")
    const navigate = useNavigate();

    const onChangeTxt = (e) => {
        const { name, value } = e.target;

        // Total Licenses Units Validation
        const numberRegex = /^[0-9]+$/
        if (name === "totalUnits") {
            if (!value.match(numberRegex)) {
                setLicenseError("Only numbers are allowed");
                setInputField({
                    ...inputField,
                    [name]: "",
                });
                return;
            }
        }

        // Number of units Validation
        if (name === "liscenceFrequency") {
            if (!value.match(numberRegex)) {
                setNoOfUnitsError("Only numbers are allowed");
                setInputField({
                    ...inputField,
                    [name]: "",
                });
                return;
            }
        }

        setInputField({
            ...inputField,
            [name]: value,
        });
        // console.log("ssssssssssss", accountId);

        setError("")
        setLicenseError("")
        setNoOfUnitsError("")

    }

    const onCreateLicense = (e) => {
        e.preventDefault();

        // License Type Validation
        if (inputField.liscenceType === "") {
            setError("Please select a License Type");
            return;
        }

        // Total Licenses Units Validation
        if (inputField.totalUnits === 0) {
            setError("Please enter Total Licenses Units");
            return;
        }

        // Choose Unit Validation
        if (inputField.liscencePeriod === "") {
            setError("Please Choose a unit");
            return;
        }

        //Number of units Validation
        if (inputField.liscenceFrequency === "") {
            setError("Please enter Number of units");
            return;
        }

        // Start Date Validation
        if (!inputField.start_date) {
            setError("Please select a Start Date.");
            return;
        }

        setError("");


        let lmAccountId = accountId;
        let payload = {
            _lmAccountId: lmAccountId,
            category: inputField.liscenceCategory,
            license_type: inputField.liscenceType,
            total_units: inputField.totalUnits,
            period: inputField.liscencePeriod,
            frequency: inputField.liscenceFrequency,
            licenseStartDate: inputField.start_date,
        }
        axios.post("/license/createLicenses", payload)
            .then((res) => {
                setApiResponse("Created Licenses")
                // sendLicenseDetails()
                setTimeout(() => {
                    createLicense([res.data])
                    navigate("../licenses/assignStudentsToLicense", {
                        state: {
                            licenseData: res.data
                        }
                    })
                }, 1000);
                console.log("Create license data", res.data);
            })
            .catch((err) => {
                console.log("Create license errorrr", err);
            })
    }

    const findExpiryDate = (period, frequency, startDate) => {
        period = inputField.liscencePeriod;
        frequency = parseInt(inputField.liscenceFrequency);
        startDate = inputField.start_date;
        console.log("Sssssssssss", period, frequency, startDate);

        console.log("Sssssssssss", period, frequency, startDate);

        const start = new Date(startDate);
        let expiry;

        if (period && frequency && startDate) {
            switch (period) {
                case 'Week':
                    expiry = new Date(start.getTime() + frequency * 7 * 24 * 60 * 60 * 1000);
                    break;
                case 'Day':
                    expiry = new Date(start.getTime() + frequency * 24 * 60 * 60 * 1000);
                    break;
                case 'Month':
                    expiry = new Date(start.getFullYear(), start.getMonth() + frequency, start.getDate());
                    break;
                case 'Year':
                    expiry = new Date(start.getTime());
                    expiry.setFullYear(expiry.getFullYear() + frequency);
                    break;
                default:
                    console.error('Invalid period value:', period);
                    break;
            }
        }

        if (expiry) {
            console.log("start.getDate()", expiry);
            expiry.setDate(expiry.getDate() - 1);
            console.log("Ssssssssssssssss 22222", expiry);
            const expiryDate = expiry.toISOString().slice(0, 10);
            console.log(expiryDate); // output: 2023-08-30
            return expiryDate;
        } else {
            console.error('Error calculating expiry date');
            return "No Date Found";
        }
    }


    return (
        <div>

            <section style={{ maxWidth: "720px", margin: "0px auto", padding: "15px" }}>
                <div>
                    <h3>
                        Create Subscription
                    </h3>
                </div>
            </section>
            <section style={{ maxWidth: "720px", margin: "0px auto", border: "1px solid lightgray", padding: "15px", borderRadius: "10px" }}>
                <Row>
                    <Col xs={12} className={"mt-3"}>
                        <div>
                            <label className='w-50' for="liscenceCategory">Choose a License Category</label>
                            <select className='w-50' name="liscenceCategory" id="liscenceCategory" style={{ height: "45px", backgroundColor: "white", border: "1px solid lightgrey", borderRadius: "5px", width: "auto" }} onChange={onChangeTxt}>
                                <option value="paid">Paid</option>
                                <option value="free">Free</option>
                            </select>
                        </div>
                    </Col>
                    <Col xs={12} className={"mt-3"}>
                        <div>
                            <label className='w-50' for="liscenceType">Choose a License Type:</label>
                            <select className='w-50' name="liscenceType" id="liscenceType" style={{ minWidth: "100px", height: "45px", backgroundColor: "white", border: "1px solid lightgrey", borderRadius: "5px", width: "auto" }} onChange={onChangeTxt}>
                                <option value="Subscription">Subscription </option>
                                <option value="Perpetual">Perpetual </option>
                                <option value="Floating">Floating </option>
                            </select>
                        </div>
                    </Col>

                    <Col xs={12} className={"mt-3"}>
                        <div className='d-flex align-items-center'>
                            <label className='w-50' for="liscense_type">Total Licenses Units:</label>
                            <div className='w-50'>
                                <PrimaryTextBox name="totalUnits" label="Total Licenses Units" onChange={onChangeTxt} />
                                {licenseError && <h6 style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>{licenseError}</h6>}
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} className={"mt-3"}>
                        <div className='d-flex align-items-center'>
                            <label className='w-50' for="liscenceFrequency"> License Duration</label>
                            <Row>
                                <Col md={6} xs={12} >
                                    <div className='w-100'>
                                        <PrimaryTextBox name="liscenceFrequency" label="Number of units" onChange={onChangeTxt} />
                                        {noOfUnitsError && <h6 style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>{noOfUnitsError}</h6>}
                                    </div>
                                </Col>
                                <Col md={6} xs={12} >
                                    <div className='w-100'>
                                        <select name="liscencePeriod" id="liscencePeriod" onChange={onChangeTxt} style={{ width: "100%", height: "50px", backgroundColor: "white", border: "1px solid lightgrey", borderRadius: "5px" }}>
                                            <option value="">Select </option>
                                            <option value="Day">Day </option>
                                            <option value="Week">Week</option>
                                            <option value="Month">Month</option>
                                            <option value="Year">Year</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </Col>
                    <Col xs={12} className={"mt-3"}>
                        <div className='d-flex align-items-center'>
                            <label className='w-50' for="start_date">Start Date</label>
                            <div className='w-50'>
                                <input type='date' name="start_date" style={{ width: "100%", height: "45px", backgroundColor: "white", border: "1px solid lightgrey", borderRadius: "5px", width: "auto" }} onChange={onChangeTxt} />
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className='mt-3 d-flex align-items-center'>

                            <div className='w-50'>
                                <h5>License Expiry:</h5>

                            </div>
                            <div className='w-50'>
                                <h5>
                                    {findExpiryDate()}
                                </h5>
                            </div>

                        </div>
                    </Col>
                </Row>
                <div className='mt-3' style={{ textAlign: "right" }}>
                    <button className='btn-latest' style={{ minHeight: "40px", minWidth: "150px", border: "none" }} onClick={onCreateLicense}>
                        Create Licenses
                    </button>
                    <h6 className='mt-2' style={{ color: "red", fontWeight: "bold" }}>{error}</h6>
                    <div>
                        {apiResponse}
                    </div>
                </div>
            </section>
        </div>
    )
}
