import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom'
import useGlobalData from '../../../../Zustand/GlobalData';
import axios from 'axios';
import NotificationPopup from '../../../CmnCompnts/NotificationPopup';

export default function AssignStudentsToLicenseAdmin() {

    const location = useLocation();
    const { usersData, userLicenses } = useGlobalData();
    const [assignedUsers, setAssignedUsers] = useState([])

    const [slctdLicenseDt, setSlctdLicenseDt] = useState([])
    const navigate = useNavigate();
    const [inputFields, setInputFields] = useState({
        productId: "",
        productVariant: "",
        courseId: ""
    })
    const [newLicense, setNewLicense] = useState("")
    const [slctdProduct, setSlctdProduct] = useState("")
    const [slctdProductVariant, setSlctdProductVariant] = useState("")
    const [allGrade, setAllGrades] = useState([])
    const [slctdGrade, setSelectedGrade] = useState("")
    const [showMsg, setShowMsg] = useState({
        message: "",
        type: ""
    })
    const [show, setShow] = useState(false)
    const [productVariantsList, setProductVariantList] = useState([])
    const [coursesList, setCoursesList] = useState([])

    const [productSelected, setProductSelected] = useState(false)
    const [countLicense, setCountLicense] = useState()

    const [filteredData, setFilteredData] = useState([])
    const [slctdRole, setSlctdRole] = useState("")

    const [selectedProductDt, setSelectedProductDt] = useState([])

    const [selectedProductVariantDt, setSelectedProductvarinatDt] = useState([])
    const [courseSelected, setCourseSelected] = useState(false)
    const [selectedVersion, setSelectedVersion] = useState("")

    const [licenseOver, setLicenseOver] = useState(false)
    const [showVersion, setShowVersion] = useState(false)

    useEffect(() => {
        console.log("location.state.licenseData", location.state.licenseData)
        if (location.state === null) {
            navigate('../licenses')
        }
        else {
            setSlctdLicenseDt(location.state.licenseData)
            setCountLicense((location.state.licenseData.total_units - location.state.allocatedLicenses))
            setFilteredData(usersData);
            // let gradesTemp = [];
            // usersData.map((dt, i) => {
            //     if (!gradesTemp.includes(dt.grade)) {
            //         gradesTemp.push(dt.grade);
            //     }
            // })

            let grades = []
            usersData.map((dt, i) => {
                let gradeTemp = dt.grade.split(",");
                gradeTemp.map((gradeDt, k) => {
                    if (!grades.includes(gradeDt)) {
                        grades.push(gradeDt)
                    }
                })
            })

            setAllGrades(grades)
        }
    }, [usersData])

    const onChangeTxt = (e) => {
        e.preventDefault()

        setInputFields({
            ...inputFields,
            [e.target.name]: e.target.value
        })
        if (e.target.name === "productId") {
            setSlctdProduct(e.target.value)
            setSlctdProductVariant("");
            setNewLicense("")
            setCourseSelected(false)
            setSelectedVersion("")
            setShowVersion(false)
        }
        if (e.target.name === "productVariant") {
            setSlctdProductVariant(e.target.value);
            onSelectProductVariants(e.target.value);

            if (selectedProductDt[0].productVariants != undefined) {
                let dt = selectedProductDt[0].productVariants.filter((dt) => dt.name === e.target.value.split("/")[0]);
                console.log("selectedProductDt", dt[0]);
                setSelectedProductvarinatDt(dt[0]);
            }
            setSelectedVersion("")
            setShowVersion(false)

            // setSelectedProductvarinatDt()
        }
        if (e.target.name === "courseId") {
            if (e.target.value !== "") {
                setNewLicense(e.target.value)
                setCourseSelected(true)
                setShowVersion(true)
            }
            else{
                setShowVersion(false)
            }
        }

        if (e.target.name === "version") {
            console.log("version---", e.target.value);
            if (e.target.value === "Select Version") {
                setSelectedVersion("")
            }
            else {
                setSelectedVersion(e.target.value)
            }
        }
    }

    const allProducts = [
        {
            name: "Tara English",
            value: "1",
            productVariants: [
                {
                    name: "Early Child Education",
                    value: "1",
                    courses: [

                    ]
                },
                {
                    name: "Young Learners",
                    value: "2",
                    courses: [
                        {
                            courseName: "Plus",
                            courseVal: 1,
                            versions: ["3.0"]
                        },
                        {
                            courseName: "Neo",
                            courseVal: 2,
                            versions: ["3.0"]
                        },
                        {
                            courseName: "Champ",
                            courseVal: 3,
                            versions: ["3.0"]
                        },
                        {
                            courseName: "Expert",
                            courseVal: 4,
                            versions: ["3.0"]
                        },
                        {
                            courseName: "Master",
                            courseVal: 5,
                            versions: ["3.0", "3.1"]
                        },
                        {
                            courseName: "Star",
                            courseVal: 6,
                            versions: ["3.0"]
                        }

                    ]
                },
                {
                    name: "Adult Learners",
                    value: "3",
                    courses: [
                        {
                            courseName: "Pro",
                            courseVal: 1,
                            versions: ["3.0"]
                        },
                        {
                            courseName: "Ace",
                            courseVal: 2,
                            versions: ["3.0"]
                        },
                        {
                            courseName: "Elite",
                            courseVal: 3,
                            versions: ["3.0"]
                        },
                        {
                            courseName: "Jazz",
                            courseVal: 4,
                            versions: ["3.0"]
                        },
                    ]
                },
                {
                    name: "Star Teacher Training",
                    value: "4",
                    courses: []
                },
                {
                    name: "Custom Sebor",
                    value: "5",
                    courses: []
                },
                {
                    name: "Professional Executive",
                    value: "6",
                    courses: []
                },
            ],
        },
        {
            name: "Tara Science",
            value: "2",
            productVariants: []
        },
        {
            name: "Tara Math",
            value: "3",
            productVariants: []
        },
        {
            name: "Tara Social Studies",
            value: "4",
            productVariants: []
        },
        {
            name: "Tara Digitial Literacy",
            value: "5",
            productVariants: []
        }
        ,
        {
            name: "Tara Accountancy",
            value: "6",
            productVariants: []
        },
        {
            name: "Tara General Knowledge",
            value: "7",
            productVariants: []
        }
    ]

    useEffect(() => {
        filterMultipleFields()
    }, [slctdGrade, slctdRole])

    const filterByGrade = (gradeVal) => {
        if (gradeVal === "all") {
            setSelectedGrade("")

        }
        else {
            // let temp = filteredData.filter((dt) => gradeVal === '' || dt.grade === gradeVal)
            // setFilteredData(temp)
            setSelectedGrade(gradeVal)
        }
    }

    const filterByRole = (roleVal) => {
        if (roleVal === "all") {
            setSlctdRole("")
        }
        else {
            setSlctdRole(roleVal)
        }
    }

    const filterMultipleFields = () => {
        let temp = usersData;
        console.log("Ssssssssssss", slctdGrade, "--", slctdRole);

        if (slctdGrade && slctdRole) {
            temp = usersData.filter((dt) => dt.grade === slctdGrade && dt.userRole === slctdRole);
        } else if (slctdGrade) {
            temp = usersData.filter((dt) => dt.grade === slctdGrade);
        } else if (slctdRole) {
            temp = usersData.filter((dt) => dt.userRole === slctdRole);
        }

        setFilteredData(temp);
    };


    const onClickCheckBox = (userId, grade, checked) => {
        if (countLicense <= 0) {
            setLicenseOver(true);
            if (!checked) {
                setCountLicense(countLicense + 1);
                setLicenseOver(false);
            }
            setShowMsg({
                message: "No License Available",
                type: "error"
            });
        } else {
            setLicenseOver(false);
            if (checked) {
                // Checkbox is checked, add the user
                const updatedUsers = [...assignedUsers, {
                    id: userId,
                    grade: grade
                }];
                console.log("sssssssssss", updatedUsers);
                setAssignedUsers(updatedUsers);
                setCountLicense(countLicense - 1);
            } else {
                // Checkbox is unchecked, remove the user
                const updatedUsers = assignedUsers.filter((user) => user.id !== userId || user.grade !== grade);
                console.log("sssssssssss", updatedUsers);
                setAssignedUsers(updatedUsers);
                setCountLicense(countLicense + 1);
            }

            if (countLicense - 1 === 0) {
                setLicenseOver(true);
            }
        }


        // if (countLicense <= 0) {
        //     setLicenseOver(true)
        //     if (!checked) {
        //         setCountLicense(countLicense + 1)
        //         setLicenseOver(false)
        //     }
        //     setShowMsg({
        //         message: "No License Available",
        //         type: "error"
        //     })
        // }
        // else {
        //     setLicenseOver(false)
        //     if (checked) {
        //         // Checkbox is checked, add the user
        //         const updatedUsers = [...assignedUsers, {
        //             id: userId,
        //             grade: grade
        //         }];
        //         console.log("sssssssssss", updatedUsers);
        //         setAssignedUsers(updatedUsers);
        //         setCountLicense(countLicense - 1)
        //     } else {
        //         // Checkbox is unchecked, remove the user
        //         const updatedUsers = assignedUsers.filter((user) => user.id !== userId || user.grade !== grade);
        //         console.log("sssssssssss", updatedUsers);
        //         setAssignedUsers(updatedUsers);
        //         setCountLicense(countLicense + 1)
        //     }
        // }
    };

    const assignLicense = () => {
        if (assignedUsers.length >= 1) {
            let payload = {
                allAssignedUsers: assignedUsers,
                selectedProduct: inputFields,
                licenseDetails: location.state.licenseData
            }
            axios.post('/license/assignUsersToLicensesByAdmin', payload)
                .then((res) => {
                    console.log("Successfully assigned the users", res.data);
                    setShowMsg({
                        message: "Successfully assigned the users",
                        type: "success"
                    })
                    setTimeout(() => {
                        navigate('../licenses')
                    }, 1000);
                    // navigate(0)
                })
                .catch((err) => {
                    console.log("Error came ", err);
                })
        }
        else {
            setShowMsg({
                message: "Please Select Users",
                type: "warning"
            })
        }
    }

    const onSelectProduct = (e) => {
        let dt = allProducts.filter((dt) => dt.name === e.target.value.split("/")[0])
        console.log('productVariants', dt[0].productVariants);
        getProductData(dt)
        setProductVariantList(dt[0].productVariants)
    }

    const onSelectProductVariants = (productVariant) => {
        // console.log('onSelectProductVariants', e, "---", slctdProduct);

        let dt = allProducts.filter((dt) => dt.name === slctdProduct.split("/")[0])
        let productVariantCourses = dt[0].productVariants.filter((dt) => dt.name === productVariant.split("/")[0])
        if (productVariantCourses[0] != undefined) {
            setCoursesList(productVariantCourses[0].courses)
            console.log('productVariants ', productVariantCourses);
        }
        // setCoursesList(dt[0].productVariants)
    }

    const licenseWithGrades = (dt) => {
        const courseMap = dt.reduce((map, { course, grade }) => {
            if (!map[course]) {
                map[course] = [grade];
            } else {
                map[course].push(grade);
            }
            return map;
        }, {});

        const formattedOutput = Object.entries(courseMap).map(([course, grades]) => {
            const formattedGrades = grades.join(', ');
            return `${course.split("/")[0]} (${formattedGrades})`;
        }).join(' and ');
        console.log("formattedOutput", formattedOutput);
        return formattedOutput;
    }

    const showAssignBtn = (productName, productVariant, course) => {
        const cleanedProductName = productName ? productName.split("/")[0] : '';
        const cleanedProductVariant = productVariant ? productVariant.split("/")[0] : '';
        const cleanedCourse = course ? course.split("/")[0] : '';

        if (!cleanedProductName && !cleanedProductVariant && !cleanedCourse) {
            return false; // All fields are empty, return true
        }

        const product = allProducts.find((product) => product.name === cleanedProductName);

        if (product && product.productVariants.length > 0) {
            if (cleanedProductVariant) {
                const variant = product.productVariants.find((variant) => variant.name === cleanedProductVariant);

                if (variant && variant.courses && variant.courses.length > 0) {
                    if (cleanedCourse) {
                        return true; // Course is selected, return true
                    } else {
                        return false; // Courses exist but no course is selected, return false
                    }
                }
            } else {
                return false; // Product variants exist but no variant is selected, return false
            }
        }
        else {
            return false
        }

        return true; // No product variants or no courses, return true

    };

    const getProductData = (dt) => {
        // let dt = allProducts.filter((dt) => dt.name === e.target.value.split("/")[0])
        setSelectedProductDt(dt)
    }

    const checkLicenseOver = (isThisCourseLicenseAlreadyExist) => {
        if (isThisCourseLicenseAlreadyExist) {
            return true
        }
        else {
            return false
        }
    };


    return (
        <div>
            <Container>
                <div className='mt-3'>
                    {location.state.licenseData != undefined ? (
                        <div>
                            <h4>
                                <b>License details</b>
                            </h4>
                            <h6>Total License: {location.state.licenseData.total_units}</h6>
                            <h6>Available License: {countLicense}</h6>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div className='mt-3'>
                    {productSelected ? (
                        <div>
                            <h4>
                                <b>Selected Product</b>
                            </h4>
                            <h6>Product Name: {inputFields.productId.split('/')[0]}</h6>
                            <h6>Product Variant: {inputFields.productVariant.split('/')[0]}</h6>
                            <h6>Course : {inputFields.courseId.split('/')[0]}</h6>
                        </div>
                    ) : (
                        <div className='primary-panel'>
                            <Row>
                                <Col xs={12} className='mb-3'>
                                    <div className='d-flex justify-content-between'>
                                        <h4>
                                            <b>Select Products</b>
                                        </h4>
                                    </div>
                                </Col>
                                <Col xs={3}>
                                    <div>
                                        <select
                                            className='dropdown-slct-action'
                                            name='productId'
                                            onChange={(e) => {
                                                onChangeTxt(e);
                                                onSelectProduct(e);
                                            }}
                                        >
                                            <option>Select Product</option>
                                            {allProducts.map((dt, i) => {
                                                return (
                                                    <option value={`${dt.name}/${dt.value}`} key={i}>
                                                        {dt.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </Col>

                                <Col xs={3}>
                                    <div>
                                        {productVariantsList.length > 0 ? (
                                            <select
                                                className='dropdown-slct-action'
                                                name='productVariant'
                                                onChange={onChangeTxt}
                                            >
                                                <option value={""}>Select Product Variant</option>
                                                {productVariantsList.map((dt2, i2) => {
                                                    return (
                                                        <option value={`${dt2.name}/${dt2.value}`} key={i2}>
                                                            {dt2.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </Col>

                                <Col xs={3}>
                                    <div>
                                        {slctdProductVariant !== '' ? (
                                            <div>
                                                {coursesList.length > 0 ? (
                                                    <select
                                                        name='courseId'
                                                        className='dropdown-slct-action'
                                                        onChange={onChangeTxt}
                                                    >
                                                        <option>Select Course</option>
                                                        {coursesList.map((courseDt, k) => {
                                                            return (
                                                                <option value={`${courseDt.courseName}/${courseDt.courseVal}`}>
                                                                    {courseDt.courseName}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                ) : ""}
                                            </div>
                                        ) : ''}
                                    </div>
                                </Col>

                                <Col xs={3}>
                                    <div>
                                        {showVersion ? (
                                            <select value={selectedVersion === "" ? "Select Version" : selectedVersion} className='dropdown-slct-action' name='version' onChange={onChangeTxt}>
                                                <option>Select Version</option>
                                                <option value={'v/1.0'}>1.0</option>
                                                <option value={'v/2.0'}>2.0</option>
                                                <option value={'v/3.0'}>3.0</option>
                                                <option value={'v/4.0'}>4.0</option>
                                                <option value={'v/5.0'}>5.0</option>
                                            </select>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </Col>

                                <Col xs={12}>
                                    <div className='mt-3 text-end'>
                                        {
                                            showAssignBtn(inputFields.productId, inputFields.productVariant, inputFields.courseId) ? <>

                                                {
                                                    selectedProductVariantDt.courses.length > 0 && selectedProductVariantDt ? <div>
                                                        {
                                                            selectedVersion !== "" ? <button
                                                                className='primry-btn'
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    // if (selectedVersion != "") {
                                                                    setProductSelected(true);
                                                                    // }
                                                                }}
                                                            >
                                                                Select Product
                                                            </button> : <div> {selectedVersion.length > 0 && selectedProductVariantDt.courses.length > 0 && selectedProductVariantDt ? "" : ""}  </div>
                                                        }

                                                    </div> : "There are no courses available for this product variant"
                                                }
                                            </> : <div>

                                                {
                                                    selectedProductDt.length > 0 ? <>
                                                        {
                                                            selectedProductDt[0].productVariants.length > 0 ? "" : <div>
                                                                {
                                                                    "There are no product variants Avaiable"
                                                                }
                                                            </div>
                                                        }
                                                    </> : ""
                                                }
                                            </div>
                                        }

                                    </div>
                                </Col>

                            </Row>
                        </div>
                    )}
                </div>
                {
                    productSelected ? (
                        <div>
                            <Row className='mt-4'>
                                <Col xs={12}>
                                    <h4>
                                        <b>Assign Students</b>
                                    </h4>
                                    <div className='mt-3 d-flex justify-content-between'>
                                        <div>
                                            Filter{' '}
                                            <select
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    filterByGrade(e.target.value);
                                                }}
                                            >
                                                <option value='all'>Select Grade</option>
                                                <option value='all'>All</option>
                                                {allGrade.length > 0 ? (
                                                    allGrade.map((dt, i) => {
                                                        return (
                                                            <option value={dt} key={i}>
                                                                {dt}
                                                            </option>
                                                        );
                                                    })
                                                ) : (
                                                    'No Grade Found'
                                                )}
                                            </select>

                                            <select className='ms-3'
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    filterByRole(e.target.value);
                                                }}
                                            >
                                                <option value='all'>Select role</option>
                                                <option value='all'>All</option>
                                                <option value='student'>Student</option>
                                                <option value='teacher'>Teacher</option>
                                                <option value='admin'>Admin</option>
                                            </select>
                                        </div>
                                        <div>
                                            <button
                                                className='btn-latest'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    assignLicense();
                                                }}
                                            >
                                                Assign Licenses
                                            </button>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={12}>
                                    <div className='primary-panel mt-3'>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <td>Reg. No.</td>
                                                    <td>Name</td>
                                                    <td width={'250px'}>Grade </td>
                                                    <td>Role </td>
                                                    <td>Already Allocated </td>
                                                    <td>New </td>
                                                </tr>
                                            </thead>
                                            {filteredData.length > 0 ? (
                                                <tbody>
                                                    {filteredData
                                                        // .filter((dt) => slctdGrade === '' || dt.grade === slctdGrade)
                                                        .map((user, i) => {
                                                            let temp = false;

                                                            let userLicenseDt;
                                                            let userCourses = [];
                                                            let isThisCourseLicenseAlreadyExist = false;
                                                            let teacherLicenses = [];
                                                            let teacherCourse = '';
                                                            if (user._licenseId.length > 0) {
                                                                userLicenses.filter((licenseDt) => {
                                                                    if (licenseDt._userId === user._id) {
                                                                        userLicenseDt = licenseDt;
                                                                        console.log(
                                                                            'licenseDt._courseId',
                                                                            licenseDt._courseId,
                                                                            '--',
                                                                            'inputFields.courseId=',
                                                                            inputFields.courseId
                                                                        );
                                                                        if (user.userRole != 'teacher') {
                                                                            if (licenseDt._courseId === inputFields.courseId) {
                                                                                isThisCourseLicenseAlreadyExist = true;
                                                                            }
                                                                            userCourses.push(userLicenseDt._courseId);
                                                                        } else {
                                                                            if (licenseDt._courseId === inputFields.courseId) {
                                                                                teacherLicenses.push({
                                                                                    grade: licenseDt._userGrade,
                                                                                    course: licenseDt._courseId,
                                                                                    isThisCourseLicenseAlreadyExist: true
                                                                                });
                                                                            } else {
                                                                                teacherLicenses.push({
                                                                                    grade: licenseDt._userGrade,
                                                                                    course: licenseDt._courseId,
                                                                                    isThisCourseLicenseAlreadyExist: false
                                                                                });
                                                                            }
                                                                            // let licensesWithGrades = ""
                                                                            teacherCourse = licenseWithGrades(teacherLicenses);
                                                                            console.log('teacherCourse', teacherCourse);
                                                                        }
                                                                    }
                                                                });
                                                            }

                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {user.userRole === 'teacher' ? (
                                                                            ''
                                                                        ) : (
                                                                            <input
                                                                                defaultChecked={
                                                                                    assignedUsers.find((dt) => dt.id === user._id) !== undefined
                                                                                }
                                                                                // disabled={isThisCourseLicenseAlreadyExist || checkLicenseOver(e.target.checked)}
                                                                                // disabled={isThisCourseLicenseAlreadyExist)}
                                                                                disabled={checkLicenseOver(isThisCourseLicenseAlreadyExist)}
                                                                                type='checkbox'
                                                                                id={user.userName}
                                                                                onChange={(e) => {
                                                                                    onClickCheckBox(user._id, user.grade, e.target.checked);
                                                                                }}
                                                                            />
                                                                        )}
                                                                        <label className='ms-2' htmlFor={user.userName}>
                                                                            {user.regNum}
                                                                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <label htmlFor={user.userName}>{user.userName}</label>
                                                                    </td>
                                                                    <td>
                                                                        {user.userRole === 'teacher' ? (
                                                                            <div>
                                                                                {user.grade.split(',').length > 1 ? (
                                                                                    <div className='d-flex align-items-center'>
                                                                                        {user.grade.split(',').map((gradeDt, num) => {
                                                                                            return (
                                                                                                <div className='me-3'>
                                                                                                    <input
                                                                                                        defaultChecked={
                                                                                                            assignedUsers.some(
                                                                                                                (dt) =>
                                                                                                                    dt.id === user._id && dt.grade === gradeDt
                                                                                                            )
                                                                                                        }
                                                                                                        disabled={
                                                                                                            teacherLicenses.some(
                                                                                                                (dt) =>
                                                                                                                    dt.isThisCourseLicenseAlreadyExist &&
                                                                                                                    dt.grade === gradeDt
                                                                                                            )
                                                                                                        }
                                                                                                        type='checkbox'
                                                                                                        value={user._id}
                                                                                                        onChange={(e) => {
                                                                                                            onClickCheckBox(
                                                                                                                user._id,
                                                                                                                gradeDt,
                                                                                                                e.target.checked
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                    <label className='ms-1'>{gradeDt}</label>
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                ) : (
                                                                                    user.grade
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            user.grade
                                                                        )}
                                                                    </td>
                                                                    <td>{user.userRole}</td>
                                                                    <td>
                                                                        {user.userRole === 'teacher' ? (
                                                                            <span>{teacherCourse}
                                                                            </span>
                                                                        ) : (
                                                                            <div>
                                                                                {userCourses.map((dt2, k) => {
                                                                                    return (
                                                                                        <span>
                                                                                            {dt2.split('/')[0]}, {' '}
                                                                                        </span>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                    <td>{temp ? newLicense.split('/')[0] : ''}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            ) : (
                                                ''
                                            )}
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        ''
                    )
                }
            </Container >
            {
                showMsg.message != '' ? (
                    <NotificationPopup
                        timer={3000}
                        setShowMsg={setShowMsg}
                        type={showMsg.type}
                        message={showMsg.message}
                        setShow={setShow}
                    />
                ) : (
                    ''
                )
            }
        </div >
    );

}