import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";



const PopupAdminComponent = (props) => {
    const { selectedUser } = props;
    const [users, setUsers] = useState([]);
    const [errorMssg, setErrorMssg] = useState("")
    const [slctdRole, setslctdRole] = useState("")
    const [inputField, setInputField] = useState({
        fullName: "",
        emailId: "",
        role: [],
        phoneNo: ""
    });

    // useEffect(() => {
    //     if (selectedUser) {
    //         setInputField({
    //             fullName: selectedUser.userName || "",
    //             emailId: selectedUser.userEmail || "",
    //             role: selectedUser.userRole ? [selectedUser.userRole] : [],
    //             phoneNo: selectedUser.userPhoneNumber || "",
    //         });
    //     }
    // }, [selectedUser]);

    useEffect(() => {
        // if (props.selectedUser) {
        setInputField(props.selectedUser);
        // }
    }, [props.selectedUser]);


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "role") {
            // setRole(value);
            setslctdRole(value);
        } else if (name === "userName") {
            if (value === "" || /^[a-zA-Z ]+$/.test(value)) {
                setInputField((prevInputField) => ({
                    ...prevInputField,
                    [name]: value,
                }));
                setErrorMssg("");
            } else {
                setErrorMssg("Only alphabets are allowed");
            }
        } else if (name === "userEmail") {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                setInputField((prevInputField) => ({
                    ...prevInputField,
                    [name]: value,
                }));
                setErrorMssg("");
            } else {
                setErrorMssg("Invalid email address");
            }
        } else if (name === "userPhoneNumber") {
            if (/^\d*$/.test(value)) {
                setInputField((prevInputField) => ({
                    ...prevInputField,
                    [name]: value,
                }));
                setErrorMssg("");
            } else {
                setErrorMssg("Only numbers are allowed.");
            }
        }
    };

    const onSave = () => {
        if (props.selectedUser.userName === "") {
            setErrorMssg("Please enter Full Name");
            return false;
        }
        if (props.selectedUser.userEmail === "") {
            setErrorMssg("Please enter Email-Id");
            return false;
        }
        if (props.selectedUser.userPhoneNumber === "") {
            setErrorMssg("Please enter Phone No.");
            return false;
        }

        setErrorMssg("");
        updateUser()

        const updatedUsers = [...users];
        updatedUsers[selectedUser.index] = { ...inputField };
        setUsers(updatedUsers);
        props.handleCloseModal();

        console.log('inputField', inputField);

    }

    const updateUser = () => {
        let payload = {
            id: selectedUser._id.toString(),
            userName: inputField.userName,
            userEmail: inputField.userEmail,
            userRole: inputField.userRole,
            userPhoneNumber: inputField.userPhoneNumber,
        };


        axios
            .post(`/admin/updateUser`, payload)
            .then((res) => {
                console.log("Updated User", res.data);
            })
            .catch((err) => {
                console.log("Error", err);
            });
        console.log("payload", payload);
    };



    return (
        <div>
            <div className="mt-3" style={{ textAlign: "center" }}>
                <Modal backdrop="static" show={props.showModal} onHide={props.handleCloseModal}>
                    <Modal.Header>
                        <div>
                            <h4>
                                Edit User
                            </h4>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>

                            <Form.Group className="mt-3 d-flex" controlId="fullName">
                                <Form.Label className="w-25">Full Name</Form.Label>
                                <Form.Control type="text" name="userName" placeholder="Enter Full Name" defaultValue={props.selectedUser.userName} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group className="mt-3 d-flex" controlId="emailId">
                                <Form.Label className="w-25">Email-ID</Form.Label>
                                <Form.Control type="text" name="userEmail" placeholder="Enter Email-ID" defaultValue={props.selectedUser.userEmail} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group className="mt-3 d-flex" controlId="role">
                                <Form.Label className="w-25">Role</Form.Label>
                                <select className="w-100 dropdown-slct-action" name="userRole" onChange={handleChange} defaultValue={props.selectedUser.userRole} style={{ height: "40px" }}>
                                    {/* {console.log("inputField.role", inputField.role)} */}
                                    <option value="">Select Role</option>
                                    <option value="Admin">Admin</option>
                                </select>
                            </Form.Group>

                            <Form.Group className="mt-3 d-flex" controlId="phoneNo">
                                <Form.Label className="w-25">Phone No.</Form.Label>
                                <Form.Control type="text" name="userPhoneNumber" placeholder="Enter Phone No." defaultValue={props.selectedUser.userPhoneNumber} onChange={handleChange} />
                            </Form.Group>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="cancel-btn" style={{ height: "40px" }} onClick={props.handleCloseModal}>
                            Cancel
                        </button>
                        <button className="prim-btn" style={{ height: "40px" }} onClick={onSave}>
                            Update
                        </button>
                    </Modal.Footer>
                    {errorMssg && <h6 style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>{errorMssg}</h6>}
                </Modal>
            </div>
        </div>
    )


}

export default PopupAdminComponent;