import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import NavBar from '../../navBar';
import axios from 'axios';
import { getAllSchoolAccountDataByIdApi } from '../../../Services/api';
import useGlobalData from '../../../Zustand/GlobalData';

export default function MainSchoolDashboard() {
    const [selectedBtn, setSelectedBtn] = useState();
    const { schoolData } = useGlobalData();


    const navigate = useNavigate();
    const location = useLocation();
    const { InitialUpdateAccountsData, usersData, userLicenses } = useGlobalData();

    useEffect(() => {
        setSelectedBtn(location.pathname.split('/')[2])
    }, [location])


    useEffect(async () => {
        try {
            let accountID = localStorage.getItem("_accountId")
            let getAllSchoolDataById = await getAllSchoolAccountDataByIdApi(accountID)
            if (getAllSchoolDataById) {
                InitialUpdateAccountsData(getAllSchoolDataById)
                console.log("Gettting all data from mongodb", getAllSchoolDataById);
            }
        } catch (error) {

        }
    }, [])

    useEffect(() => {
        let isAdminLogged = localStorage.getItem("isAdminLogged");
        if (isAdminLogged === "false" || isAdminLogged === undefined) {
            navigate('/adminlogin')
        }
    }, [location])

    return (
        <div>
            <div>
                <NavBar />
            </div>
            <div className='d-flex'>
                <div style={{ maxWidth: "250px", minWidth: "250px" }}>
                    <Col
                        xs={12}
                        className="d-flex flex-column lm-main-dashboard-school"
                        style={{
                            backgroundColor: "#ECECEC", paddingLeft: "0px", position: "fixed", width: "250px"
                        }}
                    >
                        <div>
                            <h6 className='text-center'>Selected School</h6>
                            <h4 className='text-center'>
                                <b>{schoolData.schoolName}</b>
                            </h4>
                        </div>
                        <button value="profile" className={`dashboatdBtns ${selectedBtn === "profile" ? "dashboatdBtnsActive" : ""}`}
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('profile')
                            }}>
                            Profile
                        </button>
                        {/* <button value="ManageQuiz" className={`dashboatdBtns ${selectedBtn === "ManageQuizPage" ? "dashboatdBtnsActive" : ""}`}
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('manageQuizPage')
                            }}>
                            Manage Quiz
                        </button> */}
                        <button
                            value="students"
                            className={`dashboatdBtns ${selectedBtn === "users" ? "dashboatdBtnsActive" : ""}`}
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('users')
                            }}
                        >
                            Users
                        </button>
                        <button
                            value="license"
                            className={`dashboatdBtns ${selectedBtn === "licenses" ? "dashboatdBtnsActive" : ""}`}
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('licenses')
                            }}
                        >
                            Licenses
                        </button>
                        <button
                            value="license"
                            className={`dashboatdBtns ${selectedBtn === "manageQuiz" ? "dashboatdBtnsActive" : ""}`}
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('manageQuiz')
                            }}
                        >
                            Manage Quiz
                        </button>
                    </Col>
                </div>
                <div className='w-100' style={{marginTop:"7vh"}}>
                    <Outlet />
                </div>
            </div>

        </div>
    )
}
