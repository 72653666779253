import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ApprovedUser from "./ApprovedUsers";
import PendingUsers from "./PendingUsers";
import SideBar from "./SideBar";
import { useLocation } from 'react-router-dom';

function ManageAuthorRoles(props) {
    const [inputField, setInputField] = useState({
        user_name: "",
        user_phone_num: "",
        user_email: "",
        user_password: "",
    });

    const location = useLocation();


    useEffect(() => {
        
    }, [])

    //taking inputs from user and assigning to respective variable
    const onChangeTxt = (e) => {
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value,
        });
    };

    return <Container fluid={true} style={{marginTop:"7vh"}}>
        <Row>
            <Col md={"12"}>
                <Row>
                    <Col xs={"12"}>
                        <PendingUsers />
                    </Col>
                    <Col xs={"12"}>
                        <ApprovedUser />
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
}

export default ManageAuthorRoles;