import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import NavBar from '../../navBar';
import axios from 'axios';
import { getAllSchoolAccountDataByIdApi } from '../../../Services/api';
import useGlobalData from '../../../Zustand/GlobalData';

export default function MainAuthorDashboard() {
    const navigate = useNavigate();
    const [selectedBtn, setSelectedBtn] = useState();
    const location = useLocation();


    useEffect(() => {
        setSelectedBtn(location.pathname.split('/')[2])
        let isAdminLogged = localStorage.getItem("isAdminLogged");
        console.log("isAdminLoggedisAdminLoggedisAdminLogged", isAdminLogged);
        if (isAdminLogged === "false" || isAdminLogged === undefined) {
            navigate('/adminlogin')
        }
    }, [location])

    return (
        <div>
            <div>
                <NavBar />
            </div>
            <div className='d-flex'>
                <div style={{ maxWidth: "250px", minWidth: "250px" }}>
                    <Col
                        xs={12}
                        className="d-flex flex-column lm-main-dashboard-school"
                        style={{
                            backgroundColor: "lightgray", paddingLeft: "0px", position: "fixed", width: "250px"
                        }}
                    >

                        <button value="register" className={`dashboatdBtns ${selectedBtn === "register" ? "dashboatdBtnsActive" : ""}`}
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('register')
                            }}>
                            Register Author
                        </button>
                        {/* <button value="ManageQuiz" className={`dashboatdBtns ${selectedBtn === "ManageQuizPage" ? "dashboatdBtnsActive" : ""}`}
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('manageQuizPage')
                            }}>
                            Manage Quiz
                        </button> */}
                        <button
                            value="manageRoles"
                            className={`dashboatdBtns ${selectedBtn === "manageRoles" ? "dashboatdBtnsActive" : ""}`}
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('manageRoles')
                            }}
                        >
                            Manage Authors Roles
                        </button>
                    </Col>
                </div>
                <div className='w-100'>
                    <Outlet />
                </div>
            </div>

        </div>
    )
}
