import React, { useState, useCallback, useEffect, useRef } from 'react'
import useGlobalData from '../../../Zustand/GlobalData'
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Col, Row, Table } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone'
import Papa from "papaparse";
import { Button } from '@mui/material';
import AddSchoolUser from './AddSchoolUser';
import NotificationPopup from '../../CmnCompnts/NotificationPopup';

export default function AddScholUsers(props) {

    const { InitialUpdateAccountsData, updateFullData, accountId, updateAlreadyExistingUsersData, usersData, schoolData, updateUsersData } = useGlobalData();
    const [crntData, SetCrntData] = useState("student");
    const [selectedRole, setSelectedRole] = useState("all");
    const [addedUsersData, setAddedUsersData] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const [isInvalidFileDropped, setIsInvalidFileDropped] = useState(false);
    const [showMsg, setShowMsg] = useState({
        message: "",
        type: ""
    })

    const [show, setShow] = useState(false)

    const [onDropErrMsg, setOnDropErrMsg] = useState("")

    const navigate = useNavigate();
    useEffect(() => {
        console.log("initial school data", schoolData.schoolCodeForUsers);
    }, [schoolData])

    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles[0]

        if (
            file.type !== "application/vnd.ms-excel" &&
            file.type !== "application/csv" &&
            file.type !== "text/csv"
        ) {
            console.log("File type:", file.type);
            // console.log("Error: Only CSV files are allowed", file.type)
            setErrorMessage("Only CSV files are allowed")
            setIsInvalidFileDropped(true)
            return;
        } else {
            setIsInvalidFileDropped(false);
            console.log("uploading csv file")
            Papa.parse(file, {
                complete: results => {
                    const filteredData = results.data.filter(subarray => {
                        const firstValue = subarray[0];
                        return firstValue !== "firstname" && firstValue.trim() !== "";
                    });

                    let dataWithValuPair = []
                    filteredData.map((dt, i) => {
                        if (i != 0) {
                            dataWithValuPair.push({
                                regNum: dt[0],
                                firstname: dt[1],
                                lastname: dt[2],
                                userName: dt[1] + " " + dt[2],
                                userEmail: dt[3],
                                userRole: dt[4],
                                grade: dt[5],
                                dob: dt[6],
                                phoneNum: dt[7],
                            })
                        }
                    })

                    addingValuePairsToData(dataWithValuPair, schoolData); // Pass schoolData as an argument
                    console.log("Papa.parse", dataWithValuPair);
                }
            });
        }

    }, [schoolData, usersData, addedUsersData])


    const addingValuePairsToData = (data, schoolData) => {
        let allDataTemp = [...usersData, ...data];
        let justAddedData = [...data]
        let allRegNums = [];
        let assignedUsersIds = [];
        let justAddedUsersIds = [];

        usersData.map((dt, i) => {
            if (!assignedUsersIds.includes(dt.regNum)) {
                assignedUsersIds.push(dt.regNum)
            }
        })

        data.map((dt, i) => {
            // if (!justAddedUsersIds.includes(dt.regNum)) {
            justAddedUsersIds.push(dt.regNum)
            // }
        })

        let allFilteredData = []
        let filteredAssignedUsersData = []
        console.log("justAddedUsersIds", justAddedUsersIds)

        justAddedData.map((dt, i) => {
            if (assignedUsersIds.includes(dt.regNum)) {
                filteredAssignedUsersData.push(dt)
                // allFilteredData.push({ ...dt, isUserAlreadyAssigned: true, isDuplicateFound: false });
                let duplicatesIds = justAddedUsersIds.filter((tempDt) => tempDt === dt.regNum)
                console.log("duplicatesIds hgfhgfg", duplicatesIds);
                if (duplicatesIds.length > 1) {
                    allFilteredData.push({ ...dt, isUserAlreadyAssigned: true, isDuplicateFound: true });
                }
                else {
                    allFilteredData.push({ ...dt, isUserAlreadyAssigned: true, isDuplicateFound: false });
                }

            }
            else {
                // allFilteredData.push({ ...dt, isUserAlreadyAssigned: false });
                let duplicatesIds = justAddedUsersIds.filter((tempDt) => tempDt === dt.regNum)
                console.log("duplicatesIds", duplicatesIds);
                if (duplicatesIds.length > 1) {
                    allFilteredData.push({ ...dt, isUserAlreadyAssigned: false, isDuplicateFound: true });
                }
                else {
                    allFilteredData.push({ ...dt, isUserAlreadyAssigned: false, isDuplicateFound: false });
                }
            }
        })


        console.log("allFilteredData", allFilteredData);
        console.log("assignedUsersIds", assignedUsersIds);
        console.log("filteredAssignedUsersData", filteredAssignedUsersData);

        setAddedUsersData(allFilteredData)
    };


    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    const showClikdData = (e) => {
        SetCrntData(e.target.id)
        setSelectedRole(e.target.value);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        let filteredData = addedUsersData.filter(
            // (dt) => dt.userRole === selectedRole && !dt.isDuplicateFound
            (dt) => !dt.isDuplicateFound
        );

        let data = {
            _id: accountId,
            addedUsersData: filteredData,
            addThisUsersDt: filteredData.filter((dt) => !dt.isUserAlreadyAssigned),
            updateThisUsersDt: filteredData.filter((dt) => dt.isUserAlreadyAssigned),
            schoolData: schoolData,
        };

        console.log("Ssssssssssss", addedUsersData);
        console.log("datadatadata", data);
        axios
            .post("/lmAccountsUser/addAccountUsersByAdmin", data)
            .then((res) => {
                console.log("Users Added Successfully");
                localStorage.setItem("usersAdded", "true");
                let successFullyAddedLength = filteredData.length;
                let UnsuccessfullLength = addedUsersData.length - successFullyAddedLength;

                setShowMsg({
                    message: `Successfully Added ${res.data.createdUsers.length}, Updated Users: ${res.data.updatedUsers}`,
                    type: "success",
                });
                setAddedUsersData(filteredData); // Update the state with filtered data
                if (res.data.createdUsers.length > 0) {
                    updateUsersData(res.data.createdUsers)
                }
                if (res.data.updatedUsers.length > 0) {
                    updateAlreadyExistingUsersData(res.data.updatedUsers)
                }
                setTimeout(() => {
                    // navigate(0);
                    setAddedUsersData([])
                }, 1000);
                // navigate('/dashboard/user_dashboard')
            })
            .catch((error) => console.log(error));
    };


    const fileInputRef = useRef();

    const onReImportClick = () => {
        setAddedUsersData([])
    };


    return (
        <div>
            <Row style={{ display: 'flex', padding: 30, textAlign: "center" }}>
                <Col xs={12} className='m-auto'>
                    <div className="dropZoneArea mt-3" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <p>Drop the files here ...</p> :
                                <p>Drag 'n' drop some files here, or click to select files</p>
                        }

                    </div>
                    <div className='mt-4'>
                        <h6 className='txt-red'><b>{onDropErrMsg}</b></h6>
                    </div>
                </Col>
                <Col md={12} style={{ textAlign: "start", margin: "0px auto", height: "115px" }}>
                    {errorMessage && isInvalidFileDropped && (
                        <div style={{ color: "red", textAlign: "center" }}><b>{errorMessage}</b></div>
                    )}
                    <div>
                        {
                            addedUsersData.length > 0 ? <div style={{ height: 400, width: '100%' }}>
                                <Row>
                                    <Col xs={12} className='m-auto'>
                                        <div>
                                            <h4 className='txt-bld'>Import Preview Page</h4>
                                        </div>
                                        <div className='mb-3'>
                                            <Row>
                                                <div className='d-flex align-items-center justify-content-between mt-3'>
                                                    <div>
                                                        <select onChange={showClikdData} value={selectedRole}>
                                                            <option value="all">All</option>
                                                            <option value="student">Students</option>
                                                            <option value="admin">Admins</option>
                                                            <option value="teacher">Teachers</option>
                                                            <option value="schoolAccount">School Account</option>
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <button className='primry-txt-btn ms-2' onClick={onReImportClick}>Re load file </button>
                                                        <button className='primry-txt-btn ms-2' onClick={(e) => {
                                                            e.preventDefault();
                                                            setAddedUsersData([])
                                                        }}>Cancel </button>
                                                        <button className='btn-latest ms-2' onClick={onSubmit}>Confirm Import</button>
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>

                                        <Table responsive bordered hover style={{ backgroundColor: "white" }}>
                                            <thead>
                                                <tr>
                                                    <th>Reg No./Roll No.</th>
                                                    <th>Full Name</th>
                                                    <th>Email-ID</th>
                                                    <th>Role</th>
                                                    <th>Grade</th>
                                                    <th>DOB</th>
                                                    <th>Phone No.</th>
                                                    <th>Validation Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    addedUsersData.length > 0 ? (
                                                        addedUsersData
                                                            .filter((dt) => {
                                                                if (selectedRole === "all") {
                                                                    return true
                                                                }
                                                                return dt.userRole === selectedRole
                                                            })
                                                            .map((dt, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <b>{dt.regNum}</b>
                                                                    </td>
                                                                    <td>{dt.userName}</td>
                                                                    <td>{dt.userEmail}</td>
                                                                    <td>{dt.userRole}</td>
                                                                    <td>{dt.grade}</td>
                                                                    <td>{dt.dob}</td>
                                                                    <td>{dt.phoneNum}</td>
                                                                    <td>
                                                                        {dt.isDuplicateFound ? (
                                                                            <label style={{ color: "red" }}>
                                                                                Multiple Id's Found, Please Check
                                                                            </label>
                                                                        ) : (
                                                                            <div>
                                                                                <label>
                                                                                    {dt.isUserAlreadyAssigned ? (
                                                                                        <label style={{ color: "red" }}>
                                                                                            Will be updating existing user,
                                                                                            {
                                                                                                dt.phoneNum.length != 10 || !(/^\d+$/.test(dt.phoneNum)) ? "Invalid phone number" : ""
                                                                                            }
                                                                                        </label>
                                                                                    ) : (
                                                                                        <label style={{ color: "green" }}>
                                                                                            Will be added as a new user,
                                                                                            {
                                                                                                dt.phoneNum.length != 10 || !(/^\d+$/.test(dt.phoneNum)) ? <span className='txt-red'> Invalid phone number </span> : ""
                                                                                            }
                                                                                        </label>
                                                                                    )}
                                                                                </label>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                    ) : (
                                                        "Loading"
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </div> : ""
                        }
                    </div >
                    <div>
                        {
                            showMsg.message != "" ? <NotificationPopup timer={3000} setShowMsg={setShowMsg} type={showMsg.type} setShow={setShow} message={showMsg.message} /> : ""
                        }
                    </div>

                </Col >
            </Row >
        </div >
    )
}