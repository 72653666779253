import React, { useState } from "react";
import bgImg from "../../components/Assets/authoring-bg.jpg"
import { Button, Container, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Dropdown } from 'react-bootstrap';
import logo from "../../components/Assets/logo.png"
import { FormControl } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";


const AdminRegistrationPage = () => {
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("")
    const [selectedRole, setSelectedRole] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("")
    const [roleError, setRoleError] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const roles = ['Admin', 'Supervisor', 'Analyst'];

    const navigate = useNavigate();

    const handleNameChange = (e) => {
        const inputValue = e.target.value;
        const filteredValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
        setName(filteredValue);

        if (filteredValue !== inputValue) {
            setNameError("Name can only contain alphabets");
        } else {
            setNameError("");
        }
    };

    const handleRoleChange = (eventKey) => {
        setSelectedRole(eventKey);
        setRoleError("");
    };

    const handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const filteredNum = inputValue.replace(/[^0-9]/g, '').slice(0, 10);

        if (filteredNum.length !== 10) {
            setPhoneNumberError("Phone Number must have exactly 10 digits");
        } else {
            setPhoneNumberError("");
        }

        setPhoneNumber(filteredNum);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setEmailError("");
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleNameFocus = () => {
        setNameError("");
        setPasswordError("")
    };

    const handleSubmit = () => {
        // Name validation
        if (!name.trim()) {
            setNameError("Name cannot be empty");
            return;
        }
        const nameRegex = /^[a-zA-Z\s]+$/;
        if (!nameRegex.test(name)) {
            setNameError("Name can only contain alphabets");
            return;
        }

        // Phone Number Validation
        if (!phoneNumber.trim()) {
            setPhoneNumberError("Phone Number cannot be empty");
            return;
        }

        // Email validation
        if (!email.trim()) {
            setEmailError("Email cannot be empty");
            return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError("Invalid email format");
            return;
        }

        // Password Validation
        if (!password.trim()) {
            setPasswordError("Password cannot be empty");
            return;
        }

        // Role Validation
        if (!selectedRole) {
            setRoleError("Please select a role");
            return;
        }

        const payload = {
            name: name,
            phoneNumber: phoneNumber,
            email: email,
            password: password,
            role: selectedRole
        };

        axios.post('/admin/registerUser', payload)
            .then(res => {
                setSuccessMessage("Registration successful")
                // navigate("/adminDashboard")
                console.log("Registration successful:", res.data);
            })
            .catch(error => {
                console.error("Registration failed:", error);
            });

    };


    return (
        <div style={{ position: "relative", minHeight: "30vh", backgroundSize: "cover" }} >
            <Container maxWidth="xs" style={{ backgroundColor: "white", padding: "25px", minWidth: "368px", }} >
                {/* <img src={logo} style={{ position: "absolute", top: "60px", left: "50%", transform: "translateX(-50%)", zIndex: 1, width: "200px" }} /> */}
                <div style={{ background: "white", textAlign: "center", marginBottom: "20px" }} >
                    <label style={{ fontWeight: "bold" }}>ADMIN REGISTRATION</label>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField size="small" name="enter_name" id="outlined-basic" label="Enter Name" variant="outlined" style={{ width: "100%", fontSize: "15px" }} value={name} onChange={handleNameChange} onFocus={handleNameFocus} />
                        <div style={{ textAlign: "center" }}>
                            {nameError && <label style={{ color: "red", fontSize: "15px", fontWeight: "bold", textAlign: "right" }}>{nameError}</label>}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField size="small" name="phone_number" id="outlined-basic" label="Enter Phone Number" variant="outlined" style={{ width: "100%", fontSize: "15px" }} value={phoneNumber} onChange={handlePhoneNumberChange} />
                        <div style={{ textAlign: "center" }}>
                            {phoneNumberError && (
                                <label style={{ color: "red", fontSize: "15px", fontWeight: "bold", textAlign: "center" }}>
                                    {phoneNumberError}
                                </label>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField size="small" name="enter_email" id="outlined-basic" label="Enter Email" variant="outlined" style={{ width: "100%", fontSize: "15px" }} value={email} onChange={handleEmailChange} />
                        <div style={{ textAlign: "center" }}>
                            {emailError && (
                                <label style={{ color: "red", fontSize: "15px", fontWeight: "bold", textAlign: "center" }}>
                                    {emailError}
                                </label>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField size="small" name="enter_password" id="outlined-basic" label="Enter Password" type="password" variant="outlined" style={{ width: "100%", fontSize: "15px" }} value={password} onChange={handlePasswordChange} onFocus={handleNameFocus} />
                        <div style={{ textAlign: "center" }}>
                            {passwordError && (
                                <label style={{ color: "red", fontSize: "15px", fontWeight: "bold", textAlign: "center" }}>
                                    {passwordError}
                                </label>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Dropdown onSelect={handleRoleChange}>
                                <div className="custom-dropdown-toggle">
                                    <Dropdown.Toggle variant="outlined" id="role-dropdown" >
                                        {selectedRole ? selectedRole : 'Select Role'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {roles.map((option) => (
                                            <Dropdown.Item key={option} eventKey={option}>
                                                {option}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </div>
                            </Dropdown>
                            <div style={{ textAlign: "center" }}>
                                {roleError && (
                                    <label style={{ color: "red", fontSize: "15px", fontWeight: "bold", textAlign: "center" }}>
                                        {roleError}
                                    </label>
                                )}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <button className="w-100" style={{ backgroundColor: "#8FBD56", borderColor: "#C2DF9F", color: "#fff", border: "none", minHeight: "40px", borderRadius: "5px", width: "100%" }} onClick={handleSubmit}>
                            Register
                        </button>
                        <div style={{ textAlign: "center" }}>
                            {successMessage && (
                                <label style={{ color: "green", fontSize: "15px", fontWeight: "bold", textAlign: "center" }}>
                                    {successMessage}
                                </label>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}


export default AdminRegistrationPage;