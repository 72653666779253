import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Dropdown, DropdownButton, Row } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { getAllQuestionBankSets } from "../../../Services/api";
import DatePicker from "../../CmnCompnts/DatePicker1";
import SchoolsList from "../../CmnCompnts/SchoolsList";
import { UserContext } from "../../Context/Reducer";
import AddStudents from "./AddStudents";
import CreateBatch from "./CreateBatch";

const CreateQuizPage = (props) => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [slctSchool, setSlctSchool] = useState("");
    const [inputField, setInputField] = useState({});
    const [highlightBtn, setHighlightBtn] = useState();
    const { state, dispatch } = useContext(UserContext);
    const [batches, setBatches] = useState([]);
    const [showBatch, setShowBatch] = useState("Select Batch");
    const [isSlctClicked, setIsSlctClicked] = useState(false);
    const [batchStudents, setBatchStudents] = useState([]);
    const [message, setMessage] = useState("--")
    const [date, setDate] = useState("")
    const [showPanel, setShowPanel] = useState(false)
    const [allQBSets, setQbSet] = useState([]);
    const [validations, setValidations] = useState([]);
    const [slctdQBSet, setSlctdQbSet] = useState([])
    const [areStudentsAvailable, setAreStudentsAvailable] = useState(false);
    const [selectedQB, setSelectedQB] = useState(null);

    const [startTime, setStartTime] = useState({
        "Hh": 0,
        "Mm": 0,
        "startTimeType": "",

    })

    const [endTime, setEndTime] = useState({
        "Hh": 0,
        "Mm": 0,
        "endTimeType": ""
    })

    useEffect(() => {
        getAllQBSets()
    }, [])

    const getAllQBSets = async () => {
        let questionBankSets = await getAllQuestionBankSets()
        console.log("questionBankSets", questionBankSets);
        setQbSet(questionBankSets)
    }


    const onCreateQuiz = (e) => {
        e.preventDefault()
        console.log("clicked on create");
        let isValidationChecked = checkValidation();
        if (isValidationChecked.length <= 0) {
            console.log("Validation completed");
            setMessage("");
            let batchStudentIDs = batchStudents.map((dt, index) => {
                return dt._id
            })

            let payload = {
                _lmAccountId: slctSchool.id,
                quizStatus: "Active",
                quizName: inputField.quizName,
                questionBankSetId: inputField.QbSet,
                quizBatchUsers: batchStudentIDs,
                // startDate: (`${startDate.day}/${startDate.month}/${startDate.year}`),
                startDate: inputField.startDate,
                startTime: inputField.startTime,
                endDate: inputField.endDate,
                endTime: inputField.endTime,
                createdDate: new Date()
            }

            axios.post("/quiz/createQuiz", payload)
                .then((res) => {
                    console.log("Submitted");
                    setMessage("Quiz successfully created!")
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch((err) => {
                    setMessage("Couldn't create the quiz")
                    console.log("Error while creating quiz: ", err);
                })
        }
        else {
            console.log("isValidationChecked", isValidationChecked);
            if (isValidationChecked.includes("dayDifference")) {
                setMessage("end date or time should be after start date or time")
            }
            else {
                setMessage("Please Fill all the fields *")
            }
        }
    }

    const checkValidation = () => {
        console.log("Called 1", inputField);
        let validationCalender = false;
        let requiredValidation = [];

        if (!inputField.quizName) {
            requiredValidation.push("quizName")
        }
        if (!inputField.QbSet) {
            requiredValidation.push("QbSet")
        }
        if (!inputField.startDate) {
            validationCalender = true;
            requiredValidation.push("startDate")
        }
        if (!inputField.startTime) {
            validationCalender = true;
            requiredValidation.push("startTime")
        }
        if (!inputField.endDate) {
            validationCalender = true;
            requiredValidation.push("endDate")
        }
        if (!inputField.endTime) {
            validationCalender = true;
            requiredValidation.push("endTime")
        }
        console.clear();
        //comparing two dates
        // let sd = Date.parse(inputField.startDate)
        // let ed = Date.parse(inputField.endDate)
        // let tempDiff = (sd < ed)
        // if (!tempDiff) {
        //     requiredValidation.push("dayDifference");
        // }
        if (!validationCalender) {
            const sDateTime = new Date(`${inputField.startDate} ${inputField.startTime}`); //2023-02-06 03:33
            const eDateTime = new Date(`${inputField.endDate} ${inputField.endTime}`); //2023-02-06 03:33
            let tempDiff = (Date.parse(sDateTime) < Date.parse(eDateTime))
            if (!tempDiff) {
                requiredValidation.push("dayDifference");
            }
            console.log("compare <<<<<<<<<<", sDateTime, "---", eDateTime, "Differe", tempDiff);
        }

        setValidations(requiredValidation)
        return requiredValidation
    }

    const onChangeTxt = (e) => {
        if (e) {
            e.preventDefault()
            setInputField({
                ...inputField,
                [e.target.name]: e.target.value,
            });
        }
        if (e.target.name === "questionBankSetId") {
            setHighlightBtn(e.target.value)
        }
    };

    const onStartTimeTxtChange = (e) => {
        console.log("aaaaaaaaaa1", startTime);
        setStartTime({ ...startTime, [e.target.name]: e.target.value })
    }

    const onEndTimeTxtChange = (e) => {
        console.log("aaaaaaaaaaa2", endTime)
        setEndTime({ ...endTime, [e.target.name]: e.target.value })
    }

    const onDateClick = (name, date) => {
        setInputField({
            ...inputField,
            ["startDate"]: startDate,
            ["endDate"]: endDate,
        });
    }

    const getDates = (dates) => {
        console.log("DAtessssssssss", dates);
        setStartDate(dates.from)
        setEndDate(dates.to)
    }

    const onChangeSearch = (e) => {
        setShowPanel(true);
    }

    const onQBSelect = (qb) => {
        setInputField({
            ...inputField,
            ["QbSet"]: qb,
        });
        setSelectedQB(qb.name);
    }

    const onSchoolClick = () => {
        let tempBatch = []
        tempBatch = state.selected_School.batches;
        console.log("===========", state.selected_School);
        setIsSlctClicked(true)
        setBatches(tempBatch)
    }


    return (
        <div>
            <section>
                <Row>
                    <Col xs={12}>
                        <div className="create-quiz mt-3 p-4" style={{ backgroundColor: "white", border: "2px solid #DFDFDF", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                            <Row>
                                <Col xs={6} className='mt-3'>
                                    <TextField size="medium" name="quizName" onChange={onChangeTxt} id="outlined-basic" label="Enter Quiz Name" variant="outlined" style={{ width: "100%" }} error={validations.includes("quizName") ? true : false} />
                                </Col>
                                <Col xs={6} className='mt-3'>
                                    {/* <TextField size="medium" name="user_email" id="outlined-basic" label="Select School" variant="outlined" style={{ width: "100%" }}  /> */}
                                    <SchoolsList setSlctSchool={setSlctSchool} onSchoolClick={onSchoolClick} />
                                </Col>
                                <Col xs={6} className='mt-3' >
                                    <div style={{ position: "relative" }}>
                                        <h6><b>Select Quiz Template:</b></h6>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                {selectedQB ? selectedQB : 'Select Template'}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {Array.from(new Set(allQBSets.map(dt => dt.name))).map((name, i) => {
                                                    const dt = allQBSets.find(qb => qb.name === name);
                                                    return (
                                                        <Dropdown.Item
                                                            key={dt._id}
                                                            onClick={() => {
                                                                setSlctdQbSet(dt.questionBankSet);
                                                                onQBSelect(dt);
                                                                console.log("===========");
                                                            }}
                                                        >
                                                            {dt.name}
                                                        </Dropdown.Item>
                                                    );
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>


                                    {/* <TextField size="medium" name="questionBankSetId" id="outlined-basic" label="Search here to select" variant="outlined" value={inputField.QbSet} onChange={onChangeSearch} style={{ width: "100%", marginTop: "5px" }}
                                            error={validations.includes("QbSet") ? true : false}
                                        />
                                        {showPanel ? (
                                            <div
                                                className="w-100 display-panel"
                                                style={{ zIndex: "10", backgroundColor: "white", padding: "15px", position: "absolute", left: "0px", maxWidth: "200px", boxShadow: "0px 10px 10px #D0D0D0" }}
                                            >
                                                {
                                                    QbSet.slice(0, 5).map((dt, key) => {
                                                        return (
                                                            <div>
                                                                <span
                                                                    onClick={() => {
                                                                        setShowPanel(false);
                                                                        onQBSelect(dt)
                                                                    }}
                                                                    key={dt}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    {dt}
                                                                </span>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        ) : (
                                            ""
                                        )} */}
                                </Col>
                                <Col xs={6} className='mt-3'>
                                    <Row>
                                        <Col xs={6} className=" d-flex flex-column">
                                            <div><h6><b>Start Date & Time</b></h6></div>
                                            <div className="d-flex" style={{ width: "100%", marginTop: "5px" }} >
                                                <input type={"date"} name={"startDate"} onChange={onChangeTxt} style={{ height: "56px", flex: "1.5", marginRight: "10px", borderRadius: "3px", border: `1px solid ${validations.includes("startDate") ? "red" : "#c4c4c4"}` }} />
                                                <input type={"time"} name={"startTime"} onChange={onChangeTxt} style={{ height: "56px", flex: "1", borderRadius: "3px", border: `1px solid ${validations.includes("startTime") ? "red" : "#c4c4c4"}` }} />
                                            </div>
                                        </Col>
                                        <Col xs={6} className=" d-flex flex-column">
                                            <div><h6><b>End Date & Time</b></h6></div>
                                            <div className="d-flex" style={{ width: "100%", marginTop: "5px" }} >
                                                <input type={"date"} name={"endDate"} onChange={onChangeTxt} style={{ height: "56px", flex: "1.5", marginRight: "10px", borderRadius: "3px", border: `1px solid ${validations.includes("endDate") ? "red" : "#c4c4c4"}` }} />
                                                <input type={"time"} name={"endTime"} onChange={onChangeTxt} style={{ height: "56px", flex: "1", borderRadius: "3px", border: `1px solid ${validations.includes("endTime") ? "red" : "#c4c4c4"}` }} />
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* <TextField size="medium" name="user_email" id="outlined-basic" label="Search here to select" variant="outlined" style={{ width: "100%", marginTop: "5px" }} /> */}
                                </Col>
                                <Col xs={12} className="mt-3">
                                    {
                                        isSlctClicked ? <div>
                                            <AddStudents batches={batches} isItForAdmin={false} accnt_id={state.selected_School.accnt_id} setBatchStudents={setBatchStudents} />
                                        </div> : ""
                                    }
                                </Col>
                                <Col xs={12} className="mt-1">
                                    <div className="d-flex justify-content-end align-items-center">
                                        <h6 style={{ marginRight: "15px", color: "red" }}><b>{message}</b></h6>
                                        <button className="me-2 prim-btn" onClick={onCreateQuiz} disabled={batchStudents.length === 0} >Create</button>
                                        <button className="cancel-btn" onClick={props.onCancelClick}>Cancel</button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </section>
            {
                // <Container>
                //     <Row>
                //         <Col>
                //             <div className="mt-5" style={{ backgroundColor: "white", padding: "20px", margin: "0px auto", borderRadius: "10px" }}>
                //                 <div className="mt-2">
                //                     <h5 ><b>Enter Quiz Name</b></h5>
                //                     <div className="d-flex flex-wrap mt-2">
                //                         <input name="quizName" onChange={onChangeTxt} type={"text"} placeholder={"Enter quiz name here"} />
                //                     </div>
                //                 </div>
                //                 <div className="mt-4">
                //                     <h5 ><b>Select Quiz Template</b></h5>
                //                     <div className="d-flex flex-wrap mt-2">
                //                         <button className={`slctTmpltBtn ${highlightBtn == "Template to be added" ? "prim-btn" : "unhglght-Btn"}`} onClick={onChangeTxt} name="questionBankSetId" value={"Template to be added"} variant="outline-success"  >
                //                             Template to be added
                //                         </button>
                //                         <button className={`slctTmpltBtn ${highlightBtn == "Template to be added 2" ? "prim-btn" : "unhglght-Btn"}`} onClick={onChangeTxt} name="questionBankSetId" value={"Template to be added 2"} variant="outline-success" style={{}}>
                //                             Template to be added
                //                         </button>
                //                     </div>
                //                 </div>
                //                 <div className="mt-4 ">
                //                     <h5><b>Select School</b></h5>
                //                     <div className="d-flex">
                //                         <SchoolsList setSlctSchool={setSlctSchool} />
                //                         <button className="ms-2 filterBtns" onClick={(e) => {
                //                             let tempBatch = []
                //                             tempBatch = state.selected_School.batches;
                //                             console.log("===========", state.selected_School);
                //                             setIsSlctClicked(true)
                //                             setBatches(tempBatch)
                //                         }}>Select</button>
                //                     </div>
                //                 </div>
                //                 <div>
                //                     <h5><b>Select Time</b></h5>
                //                     <div >
                //                         <Row>
                //                             <Col xs={6}>
                //                                 <span>Start Time</span>
                //                                 <div className="d-flex ">
                //                                     <input className="w-50" type="number" name="startTimeHour" onChange={onStartTimeTxtChange} placeholder={"Hh"} />
                //                                     <input className="w-50" type="number" name="startTimeMinute" onChange={onStartTimeTxtChange} placeholder={"Mm"} />
                //                                     <select name="startTimeType" onChange={onStartTimeTxtChange} >
                //                                         <option value="AM">AM</option>
                //                                         <option value="PM">PM</option>
                //                                     </select>
                //                                 </div>
                //                             </Col>
                //                             <Col xs={6}>
                //                                 <span>End Time</span>
                //                                 <div className="d-flex">
                //                                     <input className="w-50" name="endTimeHour" onChange={onEndTimeTxtChange} type="number" placeholder={"Hh"} />
                //                                     <input className="w-50" name="endTimeMinute" onChange={onEndTimeTxtChange} type="number" placeholder={"Mm"} />
                //                                     <select name="endTimeType" onChange={onEndTimeTxtChange}>
                //                                         <option value="AM">AM</option>
                //                                         <option value="PM">PM</option>
                //                                     </select>
                //                                 </div>
                //                             </Col>
                //                         </Row>
                //                         {
                //                             // Array.apply(null, { length: 12 }).map((dt, k) => {
                //                             //     return (<Dropdown.Item onClick={(e) => {
                //                             //         e.preventDefault();
                //                             //         setAmTime(k + 1)
                //                             //     }}>{k + 1}</Dropdown.Item>
                //                             //     )
                //                             // })
                //                         }
                //                         {/* <DropdownButton id="dropdown-basic-button" title={`${PmTime === 'PM' ? "PM" : PmTime}`}>
                //                     {
                //                         Array.apply(null, { length: 12 }).map((dt, k) => {
                //                             return <Dropdown.Item onClick={(e) => {
                //                                 e.preventDefault();
                //                                 setPmTime(k + 1)
                //                             }}>{k + 1}</Dropdown.Item>
                //                         })
                //                     }
                //                 </DropdownButton> */}
                //                     </div>
                //                 </div>
                //             </div>
                //         </Col>
                //         <Col>
                //             <div className="d-flex " style={{ maxWidth: "200px" }}>
                //                 <div style={{ marginLeft: "20px" }}>
                //                     <h5><b>Select Date</b></h5>
                //                     <DatePicker getDates={getDates} />
                //                 </div>
                //             </div>
                //         </Col>
                //     </Row>
                //     <Row>
                //         <Col>
                //             {
                //                 console.log("zzzzzzzzzzzzz", slctSchool)
                //             }
                //             {
                //                 isSlctClicked ? <div>
                //                     <AddStudents isItForAdmin={false} accnt_id={state.selected_School.accnt_id} setBatchStudents={setBatchStudents} />
                //                 </div> : ""
                //             }
                //         </Col>
                //     </Row>
                //     <div style={{ margin: "0px auto" }}>
                //         <div className="mt-2">
                //             <button className="m-2 prim-btn" onClick={onCreateQuiz}>Create</button>
                //             <button className="m-2 cancel-btn" onClick={props.onCancelClick}>Cancel</button>
                //         </div>
                //     </div>
                //     <div className="mt-3">
                //         <h6 style={{ color: "red" }}><b>{message}</b></h6>
                //     </div>
                // </Container >
            }
        </div >
    );
};

export default CreateQuizPage;
