import { TextField } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'
import useGlobalData from '../../../Zustand/GlobalData';
import { useNavigate } from 'react-router-dom';

export default function ChangeAndUpdatePassword(props) {
    const [show, setShow] = useState(props.show);
    const [actionType, setActionType] = useState("")
    const [inputField, setInputField] = useState({})
    const [message, setMessage] = useState("");
    const { adminId } = useGlobalData();
    const [errorMessage, setErrorMessage] = useState("");

    const handleClose = () => {
        props.setShow(false);
        setShow(false);
        setErrorMessage("")
        setInputField("")
        setMessage("")
    };
    const navigate = useNavigate();

    const onChangeTxt = (e) => {
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value
        });
        setErrorMessage("");
        setMessage("")
    };

    const sendForgotPasswordLink = (e) => {
        e.preventDefault();

        // Email validation 
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(inputField.userEmail)) {
            setErrorMessage("Invalid email format");
            return;
        }

        let payload = {
            userEmail: inputField.userEmail
        }

        axios.post("/admin/sendForgotPwdLink", payload)
            .then((res) => {
                setMessage(res.data.message)

                // if (res.data.isPasswordUpdated) {
                setTimeout(() => {
                    // localStorage.clear();
                    // navigate("/")
                    handleClose()
                }, 2000);
                // }
            })
            .catch((err) => {
                setMessage("This Email is not registered")
            })
    }

    const onChangePasswordClick = () => {
        const { oldPassword, newPassword, confirmPassword } = inputField;

        if (!oldPassword || !oldPassword.trim()) {
            setErrorMessage("Please enter Current Password");
        } else if (!newPassword || !newPassword.trim()) {
            setErrorMessage("Please enter New Password");
        } else if (!confirmPassword || !confirmPassword.trim()) {
            setErrorMessage("Please enter Confirm Password");
        } else if (newPassword !== confirmPassword) {
            setErrorMessage("New Password and Confirm New Password must match");
        } else if (oldPassword === newPassword) {
            setErrorMessage("Your New Password cannot be the same as your Current Password");
        } else {
            setInputField("");
            // handleClose();

            let payload = {
                id: adminId,
                oldPassword: inputField.oldPassword,
                newPassword: inputField.confirmPassword,
            };

            axios
                .post("/admin/updateNewPassword", payload)
                .then((res) => {
                    console.log("res.data.message", res.data.message);
                    setMessage(res.data.message);
                    if (res.data.isPasswordUpdated) {
                        setTimeout(() => {
                            localStorage.clear();
                            navigate("/")
                            handleClose()
                        }, 2000);
                    }
                })
                .catch((err) => {
                    setMessage("Error Please Close And Try Again");
                });
        }
    };

    return (
        <>
            {
                props.actionType === "changePassword" ?
                    <div>
                        <Modal show={props.show} onHide={handleClose} centered backdrop="static">
                            <Modal.Body>
                                <div>
                                    <TextField className='mt-3' size="medium" name="oldPassword" id="outlined-basic" type="password" label="CURRENT PASSWORD" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
                                    <TextField className='mt-3' size="medium" name="newPassword" id="outlined-basic" type="password" label="NEW PASSWORD" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
                                    <TextField className='mt-3' size="medium" name="confirmPassword" id="outlined-basic" type="password" label="CONFIRM NEW PASSWORD" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className='secondary-btn' onClick={handleClose}>
                                    Close
                                </button>
                                <button className='btn-latest' onClick={onChangePasswordClick}>
                                    Save
                                </button>
                            </Modal.Footer>
                            <div style={{ textAlign: "center", color: "red" }}>
                                <b>{errorMessage && <div>{errorMessage}</div>}</b>
                            </div>
                            <div className='text-center p-3'>
                                {!errorMessage && message}
                            </div>
                        </Modal>
                    </div >
                    :
                    <div>
                        <Modal show={props.show} onHide={handleClose} backdrop="static" centered>
                            <Modal.Header >
                                <Modal.Title>Forgot Password</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <TextField size="medium" name="userEmail" id="outlined-basic" label="ENTER EMAIL ADDRESS" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className='secondary-btn' onClick={handleClose}>
                                    Close
                                </button>
                                <button className='btn-latest' onClick={sendForgotPasswordLink}>
                                    Send Link
                                </button>
                            </Modal.Footer>
                            <div style={{ textAlign: "center", color: "red" }}>
                                <b>{errorMessage && <div>{errorMessage}</div>}</b>
                            </div>
                            <div className='text-center p-3'>
                                {message}
                            </div>
                        </Modal>
                    </div>
            }
        </>
    );
}
