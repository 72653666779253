import { Button, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import axios from 'axios';

function VerifyAccount(props) {

    const [schoolDetails, SetSchoolDetails] = useState([])

    useEffect(() => {
        SetSchoolDetails(props.schlDetail)
    }, [])

    const cancelClick = () => {
        props.cancelClked()
    }

    const Verifyclick = (e) => {
        e.preventDefault();
        let data = {
            schoolVerified: "Verified",
            user_email : props.schlDetail.email
        }
        axios
            .post("/admin/verifyAccountByAdmin", data)
            .then((res) => {
                console.log("Sssssssss", res)
                localStorage.setItem("schoolVerified", "true")
                props.cancelClked() //to close the verify panel
            })
        // .catch((error) => console.log(error));
    }

    return (
        <Container fluid={true} style={{ backgroundColor: "#000000bd", height: "100vh", position: "absolute", top: "0", left: "0", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", zIndex: "999" }}>
            <Row style={{ width: "100%" }}>
                <Col md={4} style={{ margin: "0px auto", backgroundColor: "white", borderRadius: "10px" }}>
                    <Row style={{ marginTop: "20px" }}>
                        <Col md={12} >
                            <h2>Account Details</h2>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "30px" }}>
                        <Col md={4} style={{ textAlign: "start" }}>
                            <h5>
                                User Name
                            </h5>
                        </Col>
                        <Col style={{ textAlign: "start" }}>
                            <h5>
                                {schoolDetails.User}
                            </h5>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "30px" }}>
                        <Col md={4} style={{ textAlign: "start" }}>
                            <h5>
                                School Name
                            </h5>
                        </Col>
                        <Col style={{ textAlign: "start" }}>
                            <h5>
                                {schoolDetails.school}
                            </h5>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "30px" }}>
                        <Col md={4} style={{ textAlign: "start" }}>
                            <h5>
                                Email ID
                            </h5>
                        </Col>
                        <Col style={{ textAlign: "start", wordWrap: "anywhere" }}>
                            <h5>
                                {schoolDetails.email}
                            </h5>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "30px" }}>
                        <Col md={12}>
                            <TextareaAutosize placeholder="Enter Comment Here" style={{ width: "100%", minHeight: "150px", resize: "none", padding: "15px", borderRadius:"5px", border:"none", border:"1px solid lightGray" }}></TextareaAutosize>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "30px" }}>
                        <Col className="mb-3 d-flex justify-content-center" md={12} style={{ textAlign: "center" }}>
                            <Button className="m-2" variant={"contained"} style={{ backgroundColor: "red" }} onClick={cancelClick}>Cancel</Button>
                            <Button className="m-2" variant={"contained"} onClick={Verifyclick}>Verify</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default VerifyAccount;