import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import useGlobalData from '../../Zustand/GlobalData';
import axios from 'axios';
import NavBarPage from '../navBar';

export default function AdminDashboardNew() {
    const [selectedBtn, setSelectedBtn] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    const { allData, accountId, env } = useGlobalData();

    useEffect(() => {
        setSelectedBtn(location.pathname.split('/')[2])
        let isAdminLogged = localStorage.getItem("isAdminLogged");
        console.log("localStorage", localStorage);
        console.log("isAdminLogged", isAdminLogged);
        if (isAdminLogged === "false" || isAdminLogged === null) {
            navigate('/adminlogin');
        }
    }, [location]);

    const createSecureIdAndRedirect = (e) => {
        let payload = {
            accountId: localStorage.getItem("adminId") || ""
        }
        axios.post("/admin/sendToAuthorAdminPortal", payload)
            .then((res) => {
                window.location.assign(
                    // `https://dev-authoring-admin.learningmatters.ai?secureId=${res.data.secureId}&code=${res.data.code}`
                    `${res.data.url}?secureId=${res.data.secureId}&env=${res.data.environment}`
                );
            })
    }

    return (
        <>
            <NavBarPage />
            <div style={{ marginTop: "7vh" }}>
                <Container fluid={true}>
                    <Row className='d-flex'>
                        <Col xs={2} className='d-flex p-0 flex-column lm-main-dashboard-school' style={{ backgroundColor: "lightgray" }}>
                            <div className='h-100 d-flex flex-column justify-content-between' >
                                <div>
                                    <button className={`dashboatdBtns ${selectedBtn === "addNewSchool" ? "dashboatdBtnsActive" : ""}`} onClick={(e) => {
                                        navigate("addNewSchool")
                                    }}>
                                        Add New School
                                    </button>
                                    <button className={`dashboatdBtns ${selectedBtn === "selectSchool" ? "dashboatdBtnsActive" : ""}`} onClick={(e) => {
                                        navigate("selectSchool")
                                    }}>
                                        Select School
                                    </button>
                                    <button className={`dashboatdBtns ${selectedBtn === "adminRegistration" ? "dashboatdBtnsActive" : ""}`} onClick={(e) => {
                                        navigate("adminRegistration")
                                    }}>
                                        Add LM Admin
                                    </button>
                                    <button className={`dashboatdBtns ${selectedBtn === "authorDashboard" ? "dashboatdBtnsActive" : ""}`} onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/authorDashboard')
                                    }}>
                                        Author Adminstration
                                    </button>
                                    <button className={`dashboatdBtns ${selectedBtn === "admin" ? "dashboatdBtnsActive" : ""}`} onClick={(e) => {
                                        navigate("admin")
                                    }}>
                                        Admin List
                                    </button>
                                    <button className={`dashboatdBtns ${selectedBtn === "display_messages" ? "dashboatdBtnsActive" : ""}`} onClick={(e) => {
                                        navigate("display_messages")
                                    }}>
                                       Tara Messages
                                    </button>
                                </div>

                                <div>
                                    <h6 className='ms-3'><b>{env}</b></h6>
                                </div>
                            </div>


                        </Col>
                        <Col xs={10} >
                            <Outlet />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
