import React, { useEffect } from 'react'
import useGlobalData from '../../../Zustand/GlobalData';
import { Col, Row } from 'react-bootstrap';

export default function SchoolProfile(props) {
  const { InitialUpdateAccountsData, allData, schoolData, usersData, userLicenses } = useGlobalData();

  useEffect(async () => {
    try {
      let allData = await schoolData;
    } catch (error) {

    }
  }, [InitialUpdateAccountsData])

  return (
    <div>
      <div className='mt-4'>
        {
          schoolData != undefined ? <div>
            <Row>
              <Col xs={6} className='m-auto'>
                <h4 className='text-center'>
                  <b>School Details</b>
                </h4>
                <Row className='mt-5' style={{ borderBottom: "1px solid lightgrey", marginBottom: "15px", paddingBottom: "15px" }}>
                  <Col xs={5}>
                    <h5>School Name</h5>
                  </Col>
                  <Col xs={6}>
                    <h5>{schoolData.schoolName}</h5>
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid lightgrey", marginBottom: "15px", paddingBottom: "15px" }}>
                  <Col xs={5}>
                    <h5>School Address</h5>
                  </Col>
                  <Col xs={6}>
                    <h5>{schoolData.schoolAddress}</h5>
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid lightgrey", marginBottom: "15px", paddingBottom: "15px" }}>
                  <Col xs={5}>
                    <h5>Phone No.</h5>
                  </Col>
                  <Col xs={6}>
                    <h5>{schoolData.schoolPhoneNum}</h5>
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid lightgrey", marginBottom: "15px", paddingBottom: "15px" }}>
                  <Col xs={5}>
                    <h5>School Code</h5>
                  </Col>
                  <Col xs={6}>
                    <h5>{schoolData.schoolCodeForUsers}</h5>
                  </Col>
                </Row>
                {/* <Row style={{ borderBottom: "1px solid lightgrey", marginBottom: "15px", paddingBottom: "15px" }}>
                  <Col xs={5}>
                    <h5>School Prefix</h5>
                  </Col>
                  <Col xs={6}>
                    <h5>{schoolData.schoolPrefix}</h5>
                  </Col>
                </Row> */}
                <Row style={{ borderBottom: "1px solid lightgrey", marginBottom: "15px", paddingBottom: "15px" }}>
                  <Col xs={5}>
                    <h5>UDISE Code</h5>
                  </Col>
                  <Col xs={6}>
                    <h5>{schoolData.udiseCode}</h5>
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid lightgrey", marginBottom: "15px", paddingBottom: "15px" }}>
                  <Col xs={5}>
                    <h5>Status</h5>
                  </Col>
                  <Col xs={6}>
                    <h5>{allData.status}</h5>
                  </Col>
                </Row>

                <Row style={{ borderBottom: "1px solid lightgrey", marginBottom: "15px", paddingBottom: "15px" }}>
                  <Col xs={5}>
                    <h5>Account Type</h5>
                  </Col>
                  <Col xs={6}>
                    <h5>B2B</h5>
                    {/* <h5>{allData.userType} B2B</h5> */}
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid lightgrey", marginBottom: "15px", paddingBottom: "15px" }}>
                  <Col xs={5}>
                    <h5>Total Users</h5>
                  </Col>
                  <Col xs={6}>
                    <h5>{usersData.length}</h5>
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid lightgrey", marginBottom: "15px", paddingBottom: "15px" }}>
                  <Col xs={5}>
                    <h5>Primary Contact Name</h5>
                  </Col>
                  <Col xs={6}>
                    <h5>{allData.userName}</h5>
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid lightgrey", marginBottom: "15px", paddingBottom: "15px" }}>
                  <Col xs={5}>
                    <h5>Primary Account Email</h5>
                  </Col>
                  <Col xs={6}>
                    <h5>{allData.userEmail}</h5>
                  </Col>
                </Row>
                <Row style={{ borderBottom: "1px solid lightgrey", marginBottom: "15px", paddingBottom: "15px" }}>
                  <Col xs={5}>
                    <h5>Password</h5>
                  </Col>
                  <Col xs={6}>
                    <h5>*******</h5>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
            : "No Data Found"
        }
      </div>
      <Row>
        <Col>
          {
            usersData != undefined > 0 ? <div>

            </div> : <div>
              No Users Found
            </div>
          }
        </Col>
      </Row>
    </div>
  )
}
